export const landUsageTypes = {
    1: "BU - bydlení všeobecné",
    2: "BV - bydlení venkovské",
    3: "BI - bydlení individuální",
    4: "BH - bydlení hromadné",
    5: "BX - bydlení jiné",
    6: "RU - rekreace všeobecná",
    7: "RI - rekreace individuální",
    8: "RZ - rekreace - zahrádkářské osady",
    9: "RO - rekreace - oddechové plochy",
    10: "RH - rekreace hromadná - rekreační areály",
    11: "RX - rekreace jiná",
    12: "OU - občanské vybavení všeobecné",
    13: "OV - občanské vybavení veřejné ",
    14: "OK - občanské vybavení komerční",
    15: "OS - občanské vybavení - sport",
    16: "OL - občanské vybavení lázeňské",
    17: "OH - občanské vybavení - hřbitovy",
    18: "OX - občanské vybavení jiné",
    19: "PU - veřejná prostranství všeobecná",
    20: "PX - veřejná prostranství jiná",
    21: "ZU - zeleň všeobecná",
    22: "ZP - zeleň - parky a parkově upravené plochy",
    23: "ZZ - zeleň - zahrady a sady",
    24: "ZO - zeleň ochranná a izolační",
    25: "ZS - zeleň sídelní ostatní",
    26: "ZK - zeleň krajinná",
    27: "ZX - zeleň jiná",
    28: "SU - smíšené obytné všeobecné",
    29: "SV - smíšené obytné venkovské",
    30: "SM - smíšené obytné městské",
    31: "SC - smíšené obytné centrální",
    32: "SX - smíšené obytné jiné",
    33: "DU - doprava všeobecná",
    34: "DS - doprava silniční",
    35: "DD - doprava drážní",
    36: "DV - doprava vodní",
    37: "DL - doprava letecká",
    38: "DK - doprava kombinovaná",
    39: "DX - doprava jiná",
    40: "TU - technická infrastruktura všeobecná",
    41: "TW - vodní hospodářství",
    42: "TE - energetika",
    43: "TS - elektronické komunikace",
    44: "TO - nakládání s odpady",
    45: "TX - technická infrastruktura jiná",
    46: "VU - výroba všeobecná",
    47: "VT - výroba těžká a energetika",
    48: "VL - výroba lehká",
    49: "VD -  výroba drobná a služby",
    50: "VS - skladové areály",
    51: "VZ - výroba zemědělská a lesnická",
    52: "VE - výroba energie z obnovitelných zdrojů",
    53: "VX - výroba jiná",
    54: "HU - smíšené výrobní všeobecné",
    55: "HX - smíšené výrobní jiné",
    56: "WU - vodní a vodohospodářské všeobecné",
    57: "WT - vodní a vodních toků",
    58: "WH - vodohospodářské",
    59: "WX - vodní a vodohospodářské jiné",
    60: "AU - zemědělské všeobecné",
    61: "AP - pole a trvalé travní porosty ",
    62: "AT - trvalé kultury",
    63: "AX - zemědělské jiné",
    64: "LU - lesní všeobecné ",
    65: "LX - lesní jiné",
    66: "NU - přírodní všeobecné",
    67: "NX - přírodní jiné",
    68: "MU - smíšené nezastavěného území všeobecné",
    69: "MX - smíšené nezastavěného území jiné",
    70: "GU - těžba nerostů všeobecná",
    71: "GD - těžba nerostů - dobývání a úprava",
    72: "GZ - těžba nerostů - stavby a zařízení",
    73: "GX - těžba nerostů jiná",
    74: "XZ - specifické zvláštního určení",
    75: "XX - specifické jiné"
};
