import "../../css/layouts.css";
import Navigation from "../../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs,
    GovButton, GovFormControl,
    GovFormFile, GovFormLabel, GovFormSelect,
    GovIcon, GovLoading, GovModal, GovPrompt,
    GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    convertIsoToDateFormat,
    convertIsoToCustomFormat, getIcon, mapBuildingPurpose,
    mapConsultationResult, mapFinancingType,
    mapProjectPhase,
    mapProjectType, parseJwt, typeToText,
    userRoleToText, externalRoleToText, convertUtilizationToPercentage, convertDateToDDMMYYYY
} from "../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function LandDetailContent() {
    const {landId} = useParams();
    const jwt = localStorage.getItem('jwt');
    const [rcPerson, setRcPerson] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [documents, setDocuments] = useState();
    const [documentToUploadType, setDocumentToUploadType] = useState(1);
    const [documentIsLoading, setDocumentIsLoading] = useState(false);
    const [town, setTown] = useState();
    const [preProjectConsultations, setPreProjectConsultations] = useState();

    const [land, setLand] = useState();
    const [landName, setLandName] = useState('');
    const [landDescription, setLandDescription] = useState('');
    const [landNotes, setLandNotes] = useState('');
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [landOwner, setLandOwner] = useState('');
    const [landUsage, setLandUsage] = useState('');
    const [financingType, setFinancingType] = useState('');
    const [buildingPermitIssued, setBuildingPermitIssued] = useState('');
    const [landArea, setLandArea] = useState('');
    const [expectedHousingArea, setExpectedHousingArea] = useState('');
    const [expectedCommercialArea, setExpectedCommercialArea] = useState('');
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [ownerDemand, setOwnerDemand] = useState('');
    const [assignedRcPerson, setAssignedRcPerson] = useState('');
    const [siteNumber, setSiteNumber] = useState('');
    const [liveProject, setLiveProject] = useState(false);
    const [confidentialInformation, setConfidentialInformation] = useState(false);
    const [ownerConsent, setOwnerConsent] = useState(false);
    const [sourceOfInformation, setSourceOfInformation] = useState('');
    const [originalUsage, setOriginalUsage] = useState('');
    const [landType, setLandType] = useState('');

    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    if (user !== null) {
        role = user.role[0].authority
    }

    const fetchData = () => {
        return fetch(process.env.REACT_APP_API_URL + "/land/" + landId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData);
                setLand(jsonData);
                setLandName(jsonData.name);
                setLandDescription(jsonData.description);
                setLandNotes(jsonData.notes);
                setSelectedProjectId(jsonData.project);
                setLandOwner(jsonData.owner);
                setLandUsage(jsonData.intendedUsage);
                setFinancingType(jsonData.financing);
                setBuildingPermitIssued(jsonData.buildingPermitDate);
                setLandArea(jsonData.landArea);
                setExpectedHousingArea(jsonData.expectedHousingArea);
                setExpectedCommercialArea(jsonData.expectedCommercialArea);
                setInvestmentAmount(jsonData.investmentAmount);
                setOwnerDemand(jsonData.ownerDemand);
                setAssignedRcPerson(jsonData.assignedRcWorker);
                setSiteNumber(jsonData.siteNumber);
                setLiveProject(jsonData.aliveProject);
                setConfidentialInformation(jsonData.confidential);
                setOwnerConsent(jsonData.ownersConsentPublication);
                setSourceOfInformation(jsonData.infoSource);
                setOriginalUsage(jsonData.originalUsage);
                setLandType(jsonData.type);
                setTown(jsonData.town);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);


    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [startOfConsultation, setStartOfConsultation] = useState('');
    const [endOfConsultation, setEndOfConsultation] = useState('');

    const [lengthOfConsultation, setLengthOfConsultation] = useState('');
    const [openPoints, setOpenPoints] = useState('');
    const [agreedPoints, setAgreedPoints] = useState('');
    let navigate = useNavigate();

    const [personArray, setPersonArray] = useState([])

    const handlePersonNameChange = (event) => {
        setPersonName(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        setPersonSurname(event.target.value);
    };
    const handlePersonTelephoneChange = (event) => {
        setPersonTelephone(event.target.value);
    };
    const handlePersonEmailChange = (event) => {
        setPersonEmail(event.target.value);
    };
    const handlePersonOrganizationChange = (event) => {
        setPersonOrganization(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        setPersonRole(event.target.value);
    };

    const handleStartOfConsultationChange = (event) => {
        setStartOfConsultation(event.target.value);
        console.log(event.target.value)
    }
    const handleEndOfConsultationChange = (event) => {
        setEndOfConsultation(event.target.value);
        console.log(event.target.value)
    }

    const handleDocumentToUploadTypeChange = (event) => {
        console.log(event.target.value)
        setDocumentToUploadType(event.target.value);
    }
    const handleAgreedPointsChange = (event) => {
        setAgreedPoints(event.target.value);
    }
    const [selectedExternalPersons, setSelectedExternalPersons] = useState([]);
    const handleSelectExternalPersonsChange = (event) => {
        console.log(event)
        console.log(selectedExternalPersons)
        setSelectedExternalPersons(Array.from(event.detail.value, option => parseInt(option.value)));
    };

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [notAuthorizedModalOpen, setNotAuthorizedModalOpen] = useState(false);

    function openDeleteModal() {
        setDeleteModalOpen(true)
    }

    function closeDeleteModal() {
        setDeleteModalOpen(false)
    }

    function openNotAuthorizedModal() {
        setNotAuthorizedModalOpen(true)
    }

    function closeNotAuthorizedModal() {
        setNotAuthorizedModalOpen(false)
        setDeleteModalOpen(false)
    }

    const mapValueToDescription = (value, mapping) => {
        return mapping[value] || "Neznáma hodnota";
    };

    const landUsageMapping = {
        1: "BU - bydlení všeobecné",
        2: "BV - bydlení venkovské",
        3: "BI - bydlení individuální",
        4: "BH - bydlení hromadné",
        5: "BX - bydlení jiné",
        6: "RU - rekreace všeobecná",
        7: "RI - rekreace individuální",
        8: "RZ - rekreace - zahrádkářské osady",
        9: "RO - rekreace - oddechové plochy",
        10: "RH - rekreace hromadná - rekreační areály",
        11: "RX - rekreace jiná",
        12: "OU - občanské vybavení všeobecné",
        13: "OV - občanské vybavení veřejné ",
        14: "OK - občanské vybavení komerční",
        15: "OS - občanské vybavení - sport",
        16: "OL - občanské vybavení lázeňské",
        17: "OH - občanské vybavení - hřbitovy",
        18: "OX - občanské vybavení jiné",
        19: "PU - veřejná prostranství všeobecná",
        20: "PX - veřejná prostranství jiná",
        21: "ZU - zeleň všeobecná",
        22: "ZP - zeleň - parky a parkově upravené plochy",
        23: "ZZ - zeleň - zahrady a sady",
        24: "ZO - zeleň ochranná a izolační",
        25: "ZS - zeleň sídelní ostatní",
        26: "ZK - zeleň krajinná",
        27: "ZX - zeleň jiná",
        28: "SU - smíšené obytné všeobecné",
        29: "SV - smíšené obytné venkovské",
        30: "SM - smíšené obytné městské",
        31: "SC - smíšené obytné centrální",
        32: "SX - smíšené obytné jiné",
        33: "DU - doprava všeobecná",
        34: "DS - doprava silniční",
        35: "DD - doprava drážní",
        36: "DV - doprava vodní",
        37: "DL - doprava letecká",
        38: "DK - doprava kombinovaná",
        39: "DX - doprava jiná",
        40: "TU - technická infrastruktura všeobecná",
        41: "TW - vodní hospodářství",
        42: "TE - energetika",
        43: "TS - elektronické komunikace",
        44: "TO - nakládání s odpady",
        45: "TX - technická infrastruktura jiná",
        46: "VU - výroba všeobecná",
        47: "VT - výroba těžká a energetika",
        48: "VL - výroba lehká",
        49: "VD -  výroba drobná a služby",
        50: "VS - skladové areály",
        51: "VZ - výroba zemědělská a lesnická",
        52: "VE - výroba energie z obnovitelných zdrojů",
        53: "VX - výroba jiná",
        54: "HU - smíšené výrobní všeobecné",
        55: "HX - smíšené výrobní jiné",
        56: "WU - vodní a vodohospodářské všeobecné",
        57: "WT - vodní a vodních toků",
        58: "WH - vodohospodářské",
        59: "WX - vodní a vodohospodářské jiné",
        60: "AU - zemědělské všeobecné",
        61: "AP - pole a trvalé travní porosty ",
        62: "AT - trvalé kultury",
        63: "AX - zemědělské jiné",
        64: "LU - lesní všeobecné ",
        65: "LX - lesní jiné",
        66: "NU - přírodní všeobecné",
        67: "NX - přírodní jiné",
        68: "MU - smíšené nezastavěného území všeobecné",
        69: "MX - smíšené nezastavěného území jiné",
        70: "GU - těžba nerostů všeobecná",
        71: "GD - těžba nerostů - dobývání a úprava",
        72: "GZ - těžba nerostů - stavby a zařízení",
        73: "GX - těžba nerostů jiná",
        74: "XZ - specifické zvláštního určení",
        75: "XX - specifické jiné"
    };

    const financingTypeMapping = {
        1: "4.1.3 – Podpora přípravy projektů",
        2: "2.10 – Dostupné bydlení",
        3: "IROP – Sociální bydlení",
        4: "PPP – Spolu-investice žadatele s bankou, developerem, investorem, apod.",
        5: "Vlastní zdroje investora",
        6: "Komerční úvěr",
        7: "Jiné",
        8: "SFPI - Bytové domy bez bariér",
        9: "SFPI - Technická infrastruktura"
    };

    const originalUsageMapping = {
        1: "Bydlení",
        2: "Cestovní ruch",
        3: "Doprava",
        4: "Občanská vybavenost",
        5: "Průmysl",
        6: "Těžba surovin",
        7: "Vojenské areály",
        8: "Zemědělství",
        9: "Jiné"
    };

    const landTypeMapping = {
        1: "Brownfield",
        2: "Nemovitost k rekonstrukci",
        3: "Hala",
        4: "Greenfield",
        5: "Lanceláře",
        6: "Jiné"
    };

    function deleteLand() {
        if (role === "HEADQUARTERS" || role === "HQ_WITH_REPORT") {
            fetch(process.env.REACT_APP_API_URL + `/land/${landId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    if (response.status === 204) {
                        navigate(`/towns/detail/${town.id}`)
                    } else {
                        return response.json().then(data => {
                            throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setNotAuthorizedModalOpen(true)
        }
    }


    return (
        <div>
            <Navigation active={"villages"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>


                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {town ?
                                <a href={`/towns/${town.id.toString().length > 6 ? "collective/" : ""}detail/${town.id}`}>
                                    {town.name} {town.id.toString().length > 6 && "(DSO)"}</a> :
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Detail pozemku ({landId})</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Detail pozemku</div>
                            <div className="gov-flexbox--justify-end">
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           size="m" href={`/edit-land/${landId}`}>Upravit
                                </GovButton>
                                {(role === "HEADQUARTERS" || role === "HQ_WITH_REPORT") &&
                                    <GovButton size="m" type="solid" wcag-label="Smazat" variant="error"
                                               onGov-click={openDeleteModal}>Smazat
                                    </GovButton>
                                }
                            </div>
                            <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                                       label="Smazat projekt" role="dialog" label-tag="h5"
                                       wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                si přejete smazat tento projekt?<br/>Tato operace je nevratná.
                                <GovButton onGov-click={closeDeleteModal} variant="primary" size="m" type="base"
                                           slot="actions"
                                           wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                </GovButton>
                                <GovButton variant="error" size="m" type="solid" slot="actions"
                                           wcag-label="Potvrdit smazání" onGov-click={deleteLand}>Smazat
                                </GovButton>
                            </GovPrompt>
                            <GovModal open={notAuthorizedModalOpen} onGov-close={closeNotAuthorizedModal}
                                      label="Nedostatečné oprávnění"
                                      wcag-close-label="Close dialog box with more information">
                                <p>Na provedení akce nemáte dostatečné oprávnění</p>
                            </GovModal>
                        </div>

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder ">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    {(landName !== "" && town) ?
                                        <div>
                                            <div className="project-detail">
                                                <div>
                                                    <h4>Název obce</h4>
                                                    <p>{town.name}</p>
                                                </div>
                                                <div>
                                                    <h4>Kód obce</h4>
                                                    <p>{town.id}</p>
                                                </div>
                                                <div>
                                                    <h4>Obec s rozšířenou působností</h4>
                                                    <p><a
                                                        href={`/towns/detail/${town.orp.id}`}>{town.orp.name + " (" + town.orp.id + ")"}</a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <h4>Název investičního pozemku</h4>
                                                    <p>{landName}</p>
                                                </div>
                                                <div>
                                                    <h4>Katastrální území</h4>
                                                    <p>{land.cadastralTerritory ? `${land.cadastralTerritory.value} (${land.cadastralTerritory.id})` : 'Katastrální území není uvedeno'}</p>
                                                </div>
                                                <div>
                                                    <h4>List vlastnictví</h4>
                                                    <p>{land.ownersList ? land.ownersList : "List vlastnictví není uveden"}</p>
                                                </div>
                                                <div>
                                                    <h4>Parcelní číslo</h4>
                                                    <p>{land.siteNumber ? land.siteNumber : "List vlastnictví není uveden"}</p>
                                                </div>
                                                <div>
                                                    <h4>Živý projekt</h4>
                                                    <p>{liveProject ? <span><strong>Ano</strong></span> :
                                                        <span><strong>Ne</strong></span>}</p>
                                                </div>
                                                <div>
                                                    <h4>Důvěrné informace</h4>
                                                    <p>{confidentialInformation ? <span><strong>Ano</strong></span> :
                                                        <span><strong>Ne</strong></span>}</p>
                                                </div>
                                                <div>
                                                    <h4>Souhlas vlastníka se zveřejněním</h4>
                                                    <p>{ownerConsent ? <span><strong>Ano</strong></span> :
                                                        <span><strong>Ne</strong></span>}</p>
                                                </div>
                                                <div>
                                                    <h4>Vlastník pozemku</h4>
                                                    <p>{land.owner && land.owner !== "NOT_SELECTED" ? land.owner.name + " - " + typeToText(land.owner.type) : "Žádný vlastník není přiřazen"}</p>
                                                </div>
                                                <div>
                                                    <h4>Kontaktní osoba za vlastníka</h4>
                                                    <p>{land.contact ? land.contact.firstname + " " + land.contact.lastname + " (" + land.contact.email+") - " + externalRoleToText(land.contact.role) : "Žádná kontaktní osoba není přiřazena"}</p>
                                                </div>
                                                {assignedRcPerson &&
                                                    <div>
                                                        <h4>Kontaktní osoba za regionální centrum</h4>
                                                        <p>{assignedRcPerson.firstname + " " + assignedRcPerson.lastname + " " + "(" + assignedRcPerson.email + ")" + " - " + userRoleToText(assignedRcPerson.role)}</p>
                                                    </div>
                                                }
                                                <div>
                                                    <h4>Způsob využití</h4>
                                                    <p>{land.intendedUsage ? mapValueToDescription(land.intendedUsage.id, landUsageMapping): "Způsob využití není uveden"}</p>
                                                </div>
                                                <div>
                                                    <h4>Typ financování</h4>
                                                    <p>{land.financing ? mapValueToDescription(land.financing, financingTypeMapping) : "Typ financování není uveden"}</p>
                                                </div>
                                                <div>
                                                    <h4>Původní využití</h4>
                                                    <p>{land.originalUsage ? mapValueToDescription(land.originalUsage, originalUsageMapping) : "Původní využití není uvedeno"}</p>
                                                </div>
                                                <div>
                                                    <h4>Typ pozemku</h4>
                                                    <p>{land.type ? mapValueToDescription(land.type, landTypeMapping) : "Typ pozemku není uveden"}</p>
                                                </div>
                                                <div>
                                                    <h4>Datum vydání stavebního povolení</h4>
                                                    <p>{land.buildingPermitDate ? convertIsoToDateFormat(land.buildingPermitDate) : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Plocha pozemku m<sup>2</sup></h4>
                                                    <p>{land.landArea ? land.landArea : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Očekávaný objem výstavby pro bydlení m<sup>2</sup></h4>
                                                    <p>{land.expectedHousingArea ? land.expectedHousingArea : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Očekávaný objem výstavby pro komerční účely m<sup>2</sup></h4>
                                                    <p>{land.expectedCommercialArea ? land.expectedCommercialArea : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Očekávaná výše investice v mil. Kč</h4>
                                                    <p>{land.investmentAmount ? land.investmentAmount : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Zdroj informací</h4>
                                                    <p>{land.infoSource ? land.infoSource : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <h4>Patří k projektu</h4>
                                                <p>{selectedProjectId ? <a href={`/projects/detail/${selectedProjectId.id}`}>{`${selectedProjectId.name} (${selectedProjectId.id})`}</a> : 'Projekt není uveden'}</p>                                            </div>
                                            <div>
                                                {land.phaseTimeTracking && (
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>Fáze projektu</th>
                                                            <th>Od</th>
                                                            <th>Do</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {land.phaseTimeTracking
                                                            .sort((a, b) => new Date(a.from) - new Date(b.from))
                                                            .map((tracking, index) => (
                                                                <tr key={index}>
                                                                    <td>{mapProjectPhase(tracking.phase)}
                                                                        {tracking.to === null && (
                                                                            <strong> (aktuální)</strong>
                                                                        )}
                                                                    </td>
                                                                    <td>{convertDateToDDMMYYYY(tracking.from)}</td>
                                                                    <td>{tracking.to ? convertDateToDDMMYYYY(tracking.to) : "N/A"}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <div>
                                                <h4>Popis investičního pozemku</h4>
                                                <p>{land.description ? land.description : "Popis projektu není uveden"}</p>
                                            </div>
                                        </div>
                                        :
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text" animation="progress" count="1" width="300px" height="30px"></GovSkeleton>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
