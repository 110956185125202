import "../../css/layouts.css";
import {
    GovAccordionItem,
    GovBreadcrumbs,
    GovButton, GovContainer, GovFormCheckbox, GovFormControl, GovFormFile, GovFormGroup,
    GovFormInput, GovFormLabel, GovFormMultiSelect, GovFormRadio, GovFormSelect,
    GovIcon, GovMessage, GovModal, GovSkeleton
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {json, useNavigate, useParams} from "react-router-dom";
import {externalRoleToText, getPersonRole, parseJwt, typeToText, userRoleToText} from "../../utils/utils";
import Select, {components} from "react-select";
import Navigation from "../../Navigation";
import {landUsageTypes} from "./landUtils";

export function EditLandContent() {

    const { landId } = useParams();
    const [townId, setTownId] = useState("");
    const [projectId, setProjectId] = useState();

    const [landName, setLandName] = useState(null);
    const [landDescription, setLandDescription] = useState(null);
    const [landNotes, setLandNotes] = useState(null);
    const [intendedUsage, setIntendedUsage] = useState('');
    const [financingType, setFinancingType] = useState('');
    const [landType, setLandType] = useState('');
    const [buildingPermitIssued, setBuildingPermitIssued] = useState();
    const [landArea, setLandArea] = useState();
    const [investmentAmount, setInvestmentAmount] = useState(null);
    const [expectedHousingArea, setExpectedHousingArea] = useState(null);
    const [expectedCommercialArea, setExpectedCommercialArea] = useState(null);
    const [landOwner, setLandOwner] = useState();
    const [ownerDemand, setOwnerDemand] = useState();
    const [selectedProjectId, setSelectedProjectId] = useState();
    const [landApplicant, setLandApplicant] = useState();
    const [stageOfLand, setStageOfLand] = useState(null);
    const [externalPerson, setExternalPerson] = useState([]);
    const [ownersList, setOwnersList] = useState();

    const [projects, setProjects] = useState([]);

    const [cadastralTerritories, setCadastralTerritories] = useState([]);
    const [cadastralTerritoriesFetched, setCadastralTerritoriesFetched] = useState(false);

    const [selectedCadastralTerritory, setSelectedCadastralTerritory] = useState('');

    const [siteNumber, setSiteNumber] = useState('');
    const [liveProject, setLiveProject] = useState(false);
    const [confidentialInformation, setConfidentialInformation] = useState(false);
    const [ownerConsent, setOwnerConsent] = useState(false);
    const [sourceOfInformation, setSourceOfInformation] = useState('');
    const [originalUsage, setOriginalUsage] = useState('');

    const [externalPersons, setExternalPersons] = useState([]);
    const [contactPerson, setContactPerson] = useState('');
    const [assignedRcPerson, setAssignedRcPerson] = useState('');
    const [assignedOwnerPerson, setAssignedOwnerPerson] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [town, setTown] = useState({});
    const [rcPeople, setRcPeople] = useState();

    const collective = townId.length > 6;
    const [landValid, setLandValid] = useState(false);

    const [nameInvalid, setNameInvalid] = useState(true)
    const [surnameInvalid, setSurnameInvalid] = useState(true)
    const [phoneInvalid, setPhoneInvalid] = useState(true)
    const [emailInvalid, setEmailInvalid] = useState(true)
    const [institutionInvalid, setInstitutionInvalid] = useState(true)
    const [roleInvalid, setRoleInvalid] = useState(true)

    const [smallerDate, setSmallerDate] = useState(false)

    const [newPersonValid, setNewPersonValid] = useState(false)

    const [applicants, setApplicants] = useState(null);
    const [landApplicantInvalid, setLandApplicantInvalid] = useState(true)

    const [personRole, setPersonRole] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');
    const [personRoleDescription, setPersonRoleDescription] = useState('');
    const [modalExternalOpen, setExternalModalOpen] = useState(false);
    const [startOfConsultation, setStartOfConsultation] = useState('');
    const [endOfConsultation, setEndOfConsultation] = useState('');

    const handleTownPersonChange = (event) => {
        setSubjectType(event.target.value);
    };

    const jwt = localStorage.getItem("jwt");
    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");

    const fetchLandData = () => {
        return fetch(process.env.REACT_APP_API_URL + "/land/" + landId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData);
                setLiveProject(jsonData.aliveProject);
                setAssignedRcPerson(jsonData.assignedRcWorker.id);
                setBuildingPermitIssued(jsonData.buildingPermitDate);
                selectedCadastralTerritory && setSelectedCadastralTerritory(jsonData.cadastralTerritory.id);
                setConfidentialInformation(jsonData.confidential);
                jsonData.contact && setContactPerson(jsonData.contact.id);
                setLandDescription(jsonData.description);
                setExpectedCommercialArea(jsonData.expectedCommercialArea);
                setExpectedHousingArea(jsonData.expectedHousingArea);
                setFinancingType(jsonData.financing);
                setSourceOfInformation(jsonData.infoSource);

                jsonData.intendedUsage && setIntendedUsage(jsonData.intendedUsage.value);
                setInvestmentAmount(jsonData.investmentAmount);
                setLandArea(jsonData.landArea);
                setLandName(jsonData.name);
                setOriginalUsage(jsonData.originalUsage);
                jsonData.owner && setLandOwner(jsonData.owner.id);
                setOwnerConsent(jsonData.ownersConsentPublication);
                setOwnersList(jsonData.ownersList)
                jsonData.project && setSelectedProjectId(jsonData.project.id);
                setSiteNumber(jsonData.siteNumber);
                setStageOfLand(jsonData.state.toString())
                setTown(jsonData.town);
                setTownId(jsonData.town.id)
                setLandType(jsonData.landType);
                fetchPeopleData(jsonData.town.regionalCenter.id);
                const externalPersonsArray = jsonData.town.externalPersons.map(externalPerson => {
                    return {value: externalPerson.id, label: externalPerson.firstname + " " + externalPerson.lastname + " - " + externalRoleToText(externalPerson.role)
                            + " (" + externalPerson.email+")"}
                })
                setExternalPersons(externalPersonsArray)
                fetchCadastralTerritories(jsonData.town.id.toString());
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchCadastralTerritories = (landTown) => {
        const townIds = [
            "400019", "500054", "500089", "500097", "500119", "500143", "500178", "500186", "500208", "500216", "500224",
            "538060", "538078", "538124", "538175", "538205", "538213", "538302", "538353", "538361", "538388", "538400",
            "538531", "538736", "538931", "538949", "539007", "539449", "539465", "539589", "539601", "539635", "539678",
            "539694", "539724", "539791", "539864", "539899", "547034", "547042", "547051", "547107", "547115", "547140",
            "547158", "547174", "547271", "547298", "547301", "547310", "547328", "547344", "547361", "547379", "547387",
            "547395", "547409", "547417"
        ];

        let townToFetch;
        if (townIds.includes(landTown)) {
            townToFetch = 554782;
        }
        else {
        townToFetch = landTown;
        }
        fetch(process.env.REACT_APP_API_URL+`/cadastral-territories?townId=${townToFetch}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const territories = data.map(territory => {
                    return {value: territory.id, label: territory.value + " (" + territory.id +")"}
                })
                setCadastralTerritories(territories);
                setCadastralTerritoriesFetched(true)
            })
            .catch(error => {
                console.error('Error fetching cadastral territories:', error);
            });
    };

    function fetchExternalPeople() {
        fetch(process.env.REACT_APP_API_URL + "/externalPersons", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                const externalPersonsArray = jsonData.map(externalPerson => {
                    return {value: externalPerson.id, label: externalPerson.firstname + " " + externalPerson.lastname + " - " + externalRoleToText(externalPerson.role)
                            + " (" + externalPerson.email+")"}
                })
                setExternalPersons(externalPersonsArray)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const fetchProjects = () => {
        let request
        if (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT") {
            request = "?regionalCenterId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const projectsArray = jsonData.map(project => {
                    return {value: project.id, label: project.name + " ("+project.id +")"}
                })
                setProjects(projectsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchPeopleData = (regionalCenterId) => {
        fetch(process.env.REACT_APP_API_URL+"/rc/"+ regionalCenterId +"/employees", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setRcPeople(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    function postNewExternalPerson() {
        return fetch(process.env.REACT_APP_API_URL+ "/externalPersons", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "firstname": personName,
                "lastname": personSurname,
                "roleDescription": personRoleDescription,
                "institution": personOrganization,
                "role": personRole,
                "email": personEmail,
                "phone": personTelephone
            })
        })
            .then(response => {
                if (response.ok) {
                    return 'Request successful';
                } else {
                    throw new Error('Request failed');
                }
            })
            .catch(error => {
                return error.message;
            });
    }


    useEffect(() => {
        fetchLandData();
        fetchProjectApplicants()
        fetchProjects();
        // fetchExternalPeople();
    }, []);

    const navigate = useNavigate();

    function putNewLand() {
        if (landValid) {
            console.log(intendedUsage);
            return fetch(process.env.REACT_APP_API_URL + `/land/${landId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    "town": townId,
                    "name": landName,
                    "description": landDescription,
                    "cadastralTerritory": selectedCadastralTerritory === "" ? null : selectedCadastralTerritory,
                    "ownersList": ownersList,
                    "siteNumber": siteNumber,
                    "aliveProject": liveProject,
                    "confidential": confidentialInformation,
                    "ownersConsentPublication": ownerConsent,
                    "project": selectedProjectId,
                    "owner": landOwner,
                    "contact": contactPerson,
                    "assignedRcWorker": assignedRcPerson,
                    "intendedUsage": intendedUsage === "" ? null : intendedUsage,
                    "financing": financingType === "" ? null : financingType,
                    "originalUsage": originalUsage,
                    "type": landType === "" ? landType : null,
                    "buildingPermitDate": buildingPermitIssued,
                    "landArea": landArea,
                    "expectedHousingArea": expectedHousingArea,
                    "expectedCommercialArea": expectedCommercialArea,
                    "investmentAmount": investmentAmount,
                    "infoSource": sourceOfInformation,
                    "state":  stageOfLand === null ? 0 : stageOfLand,
                })
            })
                .then(response => response.json())
                .then(data => {
                    navigate(`/lands/detail/${data.id}`)
                });
        }
    }

    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [subjectType, setSubjectType] = useState('');
    const [subjectName, setSubjectName] = useState('');

    function openModal(){
        setModalOpen(true)
    }
    function closeModal(){
        setModalOpen(false)
    }

    function openExternalModal(){
        setExternalModalOpen(true)
    }
    function closeExternalModal(){
        setExternalModalOpen(false)
    }

    const handlePersonNameChange = (event) => {
        setPersonName(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        setPersonSurname(event.target.value);
    };
    const handleSubjectNameChange = (event) => {
        setSubjectName(event.target.value);
    };
    const handleSubjectTypeChange = (event) => {
        setSubjectType(event.target.value);
    }

    const handleLandApplicantChange = (event) => {
        setLandOwner(event.value);
    };

    const handleRadioChange = (event) => {
        setStageOfLand(event.target.value);
    }

    function postNewProjectApplicant() {
        return fetch(process.env.REACT_APP_API_URL+ "/projectSubjects", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "name": subjectName,
                "type": subjectType,
            })
        })
            .then(response => {
                if (response.ok) {
                    return 'Request successful';
                } else {
                    throw new Error('Request failed');
                }
            })
            .catch(error => {
                return error.message;
            });
    }
    const [assignedRcEmployeeChanged, setAssignedRcEmployeeChanged] = useState(false)

    const handleAssignedRcPersonChange = (event) => {
        console.log(event.target.value)
        setAssignedRcEmployeeChanged(true)
        setAssignedRcPerson(event.target.value);
    };

    const handleLandUsageChange = (event) => {
        setIntendedUsage(event.target.value);
    };

    const handleProjectIdChange = (event) => {
        setSelectedProjectId(event.value);
    };

    const handleInvestmentAmountChange = (event) => {

        setInvestmentAmount(event.target.value);
    };

    const handleLandNameChange = (event) => {
        setLandName(event.target.value);
    };
    const handleLandDescriptionChange = (event) => {
        setLandDescription(event.target.value);
    };

    const handleExternalPersonChange = (event) => {
        setContactPerson(event.value);
    };

    const handleFinancingTypeChange = (event) => {
        setFinancingType(event.target.value);
    };

    const handleLandTypeChange = (event) => {
        setLandType(event.target.value);
    };

    const handleOwnersListChange = (event) => {
        setOwnersList(event.target.value);
    }

    const handleBuildingPermitChange = (event) => {
        setBuildingPermitIssued(event.target.value);
    };
    const handleLandAreaChange = (event) => {
        setLandArea(event.target.value);
    };

    const handleCadastralTerritoryChange = (event) => {
        setSelectedCadastralTerritory(event.value);
    };

    const handleSiteNumberChange = (event) => {
        const value = event.target.value;
        setSiteNumber(value);
    };

    const handleSourceOfInformationChange = (event) => {
        const value = event.target.value;
        setSourceOfInformation(value);
    };

    const handleOriginalUsageChange = (event) => {
        const value = event.target.value;
        setOriginalUsage(value);
    };

    const validateLand = () => {
        const isValid = landName && landDescription && assignedRcPerson;
        setLandValid(isValid);
    };

    useEffect(() => {
        validateLand();
    }, [landName, landDescription, selectedCadastralTerritory, siteNumber, sourceOfInformation, originalUsage, assignedRcPerson]);

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };

    function fetchProjectApplicants() {
        fetch(process.env.REACT_APP_API_URL+"/projectSubjects", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const applicantsArray = jsonData.map(applicant => {
                    return {value: applicant.id, label: applicant.name + " - " + typeToText(applicant.type)}
                })
                console.log(applicantsArray)
                setApplicants(applicantsArray)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    function addPerson(){
        if (newPersonValid) {
            let newItem = {
                id: crypto.randomUUID(),
                name: personName,
                surname: personSurname,
                telephone: personTelephone,
                organization: personOrganization,
                email: personEmail,
                role: personRole
            }
            postNewExternalPerson().then(() => {
                // fetchExternalPeople();
            });

            setPersonName("")
            setPersonSurname("")
            setPersonTelephone("")
            setPersonEmail("")
            setPersonOrganization("")
            setPersonRoleDescription("")
            setPersonRole("STATUTORY_DEPUTY")
            closeModal()
        }
    }

    const handleResidentAreaChange = (event) => {
        setExpectedHousingArea(event.target.value);
    };

    const handlePersonTelephoneChange = (event) => {
        if (!/^\d{9}$/.test(event.target.value)) {
            setPhoneInvalid(true)
        } else {
            setPhoneInvalid(false)
        }
        setPersonTelephone(event.target.value);
    };
    const handlePersonEmailChange = (event) => {
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value.toLowerCase())) {
            setEmailInvalid(true)
        } else {
            setEmailInvalid(false)
        }
        setPersonEmail(event.target.value);
    };
    const handlePersonOrganizationChange = (event) => {
        if (event.target.value === "") {
            setInstitutionInvalid(true)
        } else {
            setInstitutionInvalid(false)
        }
        setPersonOrganization(event.target.value);
    };
    const handlePersonRoleDescriptionChange = (event) => {
        setPersonRoleDescription(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        if (event.target.value === "") {
            setRoleInvalid(true)
        } else {
            setRoleInvalid(false)
        }
        setPersonRole(event.target.value);
    };

    const handleComercialFloorAreaChange = (event) => {
        setExpectedCommercialArea(event.target.value);
    };

    function addApplicant(){

        postNewProjectApplicant().then(() => {
            fetchProjectApplicants();
        });

        setSubjectName("")
        setSubjectType("")
        closeModal()
    }

    return (
        <div>
            <Navigation active={"lands"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {town ?
                                <a href={`/towns/${townId.length > 6 ? "collective/" : ""}detail/${townId}`}>
                                    {town.name} {townId.length > 6 && "(DSO)"}</a> :
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Editace pozemku</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-title--beta">Editace pozemku</div>

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    <form method="post">
                                        <GovFormLabel className="gov-registration-label mb-20" slot="top"
                                                      size="xl">Obecné</GovFormLabel>

                                        <h4>{collective ? "Název dobrovolného sdružení obcí" : "Název obce"}</h4>
                                        <p>{town.name}</p>
                                        <h4>{collective ? "Kód dobrovolného sdružení obcí" : "Kód obce"}</h4>
                                        <p>{town.id}</p>
                                        <GovFormControl>
                                            <GovFormInput value={landName} onGov-input={handleLandNameChange}
                                                          size="s" variant="secondary"
                                                          input-type="text" placeholder="Název investičního pozemku"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Název investičního pozemku*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="text" multiline="true"
                                                          placeholder="Uveďte prosím popis investičního pozemku"
                                                          value={landDescription} onGov-input={handleLandDescriptionChange}>
                                            </GovFormInput>
                                            <GovFormLabel slot="top" size="s">Popis investičního pozemku*</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                checked={liveProject} onGov-change={(e) => setLiveProject(e.target.checked)}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Živý projekt</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                checked={ownerConsent} onGov-change={(e) => setOwnerConsent(e.target.checked)}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Souhlas vlastníka</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                onGov-change={(e) => setConfidentialInformation(e.target.checked)}
                                                checked={confidentialInformation}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Důvěrné informace</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>


                                        <GovFormControl>
                                            <div>
                                                {projects.length !== 0 ?
                                                    <Select options={projects} onChange={handleProjectIdChange}
                                                            placeholder={"Id projektu"}
                                                            value={projects.find(project => project.value === selectedProjectId)}
                                                            components={{ DropdownIndicator }}
                                                            styles={customStyles}
                                                            noOptionsMessage={() => "Nenašli jsme projekty"}

                                                    />
                                                    : <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                                                   animation="progress" count="1" width="300px"
                                                                   height="30px"></GovSkeleton>
                                                }
                                            </div>
                                            <GovFormLabel slot="top" size="m">Vyberte ID projektu, se kterým je pozemek spojen</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <div>
                                                {cadastralTerritoriesFetched ?
                                                    <Select options={cadastralTerritories} onChange={handleCadastralTerritoryChange}
                                                            placeholder={"Katastrální území"}
                                                            value={cadastralTerritories.find(cadastralTerritory => cadastralTerritory.value === selectedCadastralTerritory)}
                                                            components={{ DropdownIndicator }}
                                                            styles={customStyles}
                                                            noOptionsMessage={() => "Nenašli jsme katastrální území"}

                                                    />
                                                    : <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                                                   animation="progress" count="1" width="300px"
                                                                   height="30px"></GovSkeleton>
                                                }
                                            </div>
                                            <GovFormLabel slot="top" size="s">Katastrální území</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput value={ownersList} onGov-input={handleOwnersListChange}
                                                          size="s" variant="secondary" input-type="text" placeholder="List vlastnictví"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">List vlastnictví</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput value={siteNumber} onGov-input={handleSiteNumberChange}
                                                          size="s" variant="secondary" input-type="text" placeholder="Číslo parcely"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Číslo parcely</GovFormLabel>
                                        </GovFormControl>

                                        {/*<GovFormControl>*/}
                                        {/*    <GovFormInput size="s" variant="secondary"*/}
                                        {/*                  input-type="text" multiline="true"*/}
                                        {/*                  placeholder="Uveďte prosím poznámky k lokalitě"*/}
                                        {/*                  value={landNotes} onGov-input={handleLandNotesChange}>*/}
                                        {/*    </GovFormInput>*/}
                                        {/*    <GovFormLabel slot="top" size="s">Poznámky k lokalitě</GovFormLabel>*/}
                                        {/*</GovFormControl>*/}

                                        <GovFormControl>
                                            <div>
                                                {applicants && applicants.length !== 0 &&
                                                    <Select options={applicants} onChange={handleLandApplicantChange}
                                                            placeholder={"Vlastník lokality"}
                                                            value={applicants.find(applicant => applicant.value === landOwner)}
                                                            components={{DropdownIndicator}}
                                                            styles={customStyles}
                                                            noOptionsMessage={() => "Nenašli jsme žádné subjekty"}

                                                    />
                                                }
                                            </div>
                                            <GovFormLabel slot="top" size="s">Vlastník pozemku</GovFormLabel>
                                            <GovButton variant="primary" size="s" type="link"
                                                       wcag-label="Open dialog box for more information"
                                                       onGov-click={openModal}>+ Přidat další subjekt
                                            </GovButton>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <div>
                                                {externalPersons && externalPersons.length !== 0 ?
                                                    <Select options={externalPersons} onChange={handleExternalPersonChange}
                                                            placeholder={"Kontaktní osoba"}
                                                            value={externalPersons.find(externalPerson => externalPerson.value === contactPerson)}
                                                            components={{DropdownIndicator}}
                                                            styles={customStyles}
                                                            noOptionsMessage={() => "Nenašli jsme žádné osoby"}

                                                    /> :
                                                    <span>V obci nejsou evidovány žádné osoby. Pro založení externí osoby je nutné založit konzultaci s dalšími účastníky.</span>
                                                }
                                            </div>
                                            <GovFormLabel slot="top" size="s">Kontaktní osoba za vlastníka</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormSelect value={intendedUsage} onGov-change={handleLandUsageChange} size="s" variant="secondary">
                                                <option disabled="disabled" value="">Účel pozemku</option>
                                                {Object.keys(landUsageTypes).map((key) => (
                                                    <option value={landUsageTypes[key].substring(0, 2)} key={key}>{landUsageTypes[key]}</option>
                                                ))}
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Způsob využití</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormSelect value={financingType}
                                                           onGov-change={handleFinancingTypeChange}
                                                           size="s" variant="secondary">
                                                <option disabled="disabled" value="">Typ financování (převažující)
                                                </option>
                                                <option value="1">4.1.3 – Podpora
                                                    přípravy projektů
                                                </option>
                                                <option value="2">2.10 – Dostupné bydlení</option>
                                                <option value="3">IROP – Sociální bydlení</option>
                                                <option value="8">SFPI - Bytové domy bez bariér</option>
                                                <option value="9">SFPI - Technická infrastruktura</option>
                                                <option
                                                    value="4">PPP
                                                    – Spolu-investice žadatele s bankou, developerem, investorem, apod.
                                                </option>
                                                <option value="5">Vlastní zdroje investora
                                                </option>
                                                <option value="6">Komerční úvěr
                                                </option>
                                                <option value="7">Jiné
                                                </option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Typ financování (převažující)</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormSelect value={originalUsage}
                                                           onGov-change={handleOriginalUsageChange}
                                                           size="s" variant="secondary">
                                                <option disabled="disabled" value="">Původní využití</option>
                                                <option value="1">Bydlení</option>
                                                <option value="2">Cestovní ruch</option>
                                                <option value="3">Doprava</option>
                                                <option value="4">Občanská vybavenost</option>
                                                <option value="5">Průmysl</option>
                                                <option value="6">Těžba surovin</option>
                                                <option value="7">Vojenské areály</option>
                                                <option value="8">Zemědělství</option>
                                                <option value="9">Jiné</option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Původní využití</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormSelect value={landType} onGov-change={handleLandTypeChange} size="s" variant="secondary">
                                                <option disabled="disabled" value="">Typ pozemku</option>
                                                <option value="1">Brownfield</option>
                                                <option value="2">Nemovitost k rekonstrukci</option>
                                                <option value="3">Hala</option>
                                                <option value="4">Greenfield</option>
                                                <option value="5">Kanceláře</option>
                                                <option value="6">Jiné</option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Typ pozemku</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormLabel slot="top" size="s">Datum vydání stavebního povolení</GovFormLabel>
                                            <GovFormInput
                                                size="s" variant="secondary"
                                                input-type="date"
                                                value={buildingPermitIssued}
                                                onGov-input={handleBuildingPermitChange}>
                                            </GovFormInput>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleLandAreaChange}
                                                          input-type="number" min="0"
                                                          value={landArea}
                                                          placeholder="Plocha pozemku - m2"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Plocha pozemku - m2</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleResidentAreaChange}
                                                          input-type="number" min="0"
                                                          value={expectedHousingArea}
                                                          placeholder="Očekávaný objem výstavby - bydlení - m2"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Očekávaný objem výstavby - bydlení - m2</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleComercialFloorAreaChange}
                                                          input-type="number" min="0"
                                                          value={expectedCommercialArea}
                                                          placeholder="Očekávaný objem výstavby - komerční - m2"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Očekávaný objem výstavby - komerční - m2</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleInvestmentAmountChange}
                                                          value={investmentAmount}
                                                          input-type="number" min="0"
                                                          placeholder="Očekávaná výše investice (v mil. Kč)"></GovFormInput>
                                            <GovFormLabel slot="top" size="">Očekávaná výše investice (v mil.
                                                Kč)</GovFormLabel>
                                        </GovFormControl>

                                        <GovModal open={modalOpen} onGov-close={closeModal}
                                                  label="Přidání dalšího subjektu"
                                                  wcag-close-label="Close dialog box with more information">
                                            <GovFormControl>
                                                <GovFormInput value={subjectName}
                                                              onGov-input={handleSubjectNameChange}
                                                              size="s" variant="secondary"
                                                              input-type="text" placeholder="Uveďte prosím název subjektu"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Název subjektu*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <div className="sfpi-title-section mt-20">Typ subjektu
                                                </div>
                                                <GovFormGroup gap="s">
                                                    <GovFormRadio checked={subjectType === "TOWN"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="TOWN" size="s"
                                                                  name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Obec/Město/Městská část
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "FUNDED_ORGANIZATION"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="FUNDED_ORGANIZATION"
                                                                  size="s"
                                                                  name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Příspěvková
                                                            organizace</GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "COUNTRY"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="COUNTRY" size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Stát
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "CHURCH"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="CHURCH"
                                                                  size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Církev
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "ASSOCIATION"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="ASSOCIATION"
                                                                  size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Spolek
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "PRIVATE"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="PRIVATE"
                                                                  size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Privátní subjekt
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                </GovFormGroup>
                                            </GovFormControl>
                                            <GovButton variant="primary" size="m" type="solid"
                                                       wcag-label="Open dialog box for more information"
                                                       onGov-click={addApplicant}>Přidat
                                            </GovButton>
                                        </GovModal>

                                        <GovFormControl>
                                            <GovFormInput value={sourceOfInformation} onGov-input={handleSourceOfInformationChange}
                                                          size="s" variant="secondary" input-type="text" placeholder="Zdroj informací"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Zdroj informací</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormGroup gap="m">
                                                {
                                                    rcPeople &&
                                                    [...rcPeople]
                                                        .sort((a, b) => a.lastname.localeCompare(b.lastname))
                                                        .map((person, index) => (
                                                            <GovFormRadio
                                                                onGov-change={handleAssignedRcPersonChange}
                                                                key={index}
                                                                value={person.id}
                                                                size="s"
                                                                checked={person.id == assignedRcPerson}
                                                                name="rc-employee"
                                                            >
                                                                <GovFormLabel size="s" slot="label">
                                                                    {person.firstname + " " + person.lastname + " - " + userRoleToText(person.role)}
                                                                </GovFormLabel>
                                                            </GovFormRadio>
                                                        ))
                                                }
                                            </GovFormGroup>
                                            <GovFormLabel slot="top" size="s">Kontaktní osoba za RC*</GovFormLabel>
                                        </GovFormControl>

                                        <div className="sfpi-title-section mt-20">Která fáze projektu je hotová
                                        </div>
                                        <GovFormControl>
                                            <GovFormGroup gap="s">
                                                <GovFormRadio onGov-change={handleRadioChange} value="1" size="s"
                                                              name="list-radio" checked={"1" === stageOfLand}>
                                                    <GovFormLabel size="s" slot="label">Vize</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange}
                                                              value="2" size="s" name="list-radio" checked={"2" === stageOfLand}>
                                                    <GovFormLabel size="s" slot="label">Předprojektová
                                                        příprava</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange} value="3"
                                                              size="s" name="list-radio" checked={"3" === stageOfLand}>
                                                    <GovFormLabel size="s" slot="label">Projektová práce</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange}
                                                              value="4" size="s"
                                                              name="list-radio" checked={"4" === stageOfLand}>
                                                    <GovFormLabel size="s" slot="label">Stavební povolení - příprava na
                                                        realizaci</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange}
                                                              value="5" checked={"5" === stageOfLand} size="s"
                                                              name="list-radio">
                                                    <GovFormLabel size="s" slot="label">Realizace a provoz
                                                        budovy</GovFormLabel>
                                                </GovFormRadio>
                                            </GovFormGroup>
                                        </GovFormControl>

                                        { !landValid &&
                                            <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné položky</p></GovMessage>}
                                        <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="solid" size="m" onGov-click={putNewLand} disabled={!landValid}>Uložit
                                        </GovButton>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
