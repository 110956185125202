import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {EditLandContent} from "../components/contentComponents/Land/EditLandContent";

export function EditLand() {
    return (
        <div>
            <Header></Header>
            <EditLandContent></EditLandContent>
            <Footer></Footer>
        </div>
    )
}
