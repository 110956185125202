import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {ExternalConsultationsContent} from "../components/contentComponents/ExternalConsultationsContent";

export function ExternalConsultations() {
    return (
        <div>
            <Header></Header>
            <ExternalConsultationsContent></ExternalConsultationsContent>
            <Footer></Footer>
        </div>
    )
}
