import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {LandsContent} from "../components/contentComponents/Land/LandsContent";

export function Lands() {
    let user = {
        role: 'RC_LEADER'
    }
    return (
        <div>
            <Header></Header>
            <LandsContent user={user}></LandsContent>
            <Footer></Footer>
        </div>
    )
}
