import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import DOMPurify from 'dompurify';
import { mapRegionalCenter, userRoleToText } from "../components/utils/utils";
import {GovSkeleton} from "@gov-design-system-ce/react";

const EmployeeCard = ({ user }) => {
    const [photoUrl, setPhotoUrl] = useState(null);

    if (user.regionalCenter) {
        user.rcId = user.regionalCenter.id;
    }

    let userEmail = user.email ? user.email : user.loginEmail;
    if (userEmail) {
        userEmail = userEmail.trim().toLowerCase();
    }

    const emailHash = CryptoJS.MD5(userEmail).toString();
    const jwt = localStorage.getItem("jwt");

    const [photoFetched, setPhotoFetched] = useState(false);

    useEffect(() => {
        function fetchUserPhoto(userId) {
            fetch(process.env.REACT_APP_API_URL + `/users/${userId}/bio/profilePicture`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                },
            })
                .then(response => {
                    if (response.status === 404) {
                        setPhotoFetched(true);
                        return null;
                    }
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    if (blob) {
                        const url = window.URL.createObjectURL(blob);
                        setPhotoUrl(url);
                        setPhotoFetched(true);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }

        fetchUserPhoto(user.id);
    }, [user.profilePhotoChanged, user, jwt]);

    return (
        <div className="employee-card">
            <div className="employee-card-left">
                {user && photoFetched ? (
                    photoUrl ? (
                        <img src={photoUrl} alt={`${user.firstname} ${user.lastname}`} className="employee-photo" />
                    ) : (
                        <img src={`https://www.gravatar.com/avatar/${emailHash}?d=identicon`} alt="Avatar Placeholder" className="employee-photo" />
                    )
                ) : (
                    <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="circle"
                                 animation="progress" count="1" width="130px" height="130px"></GovSkeleton>
                )}

                <div className="employee-name-title">
                    <h3>{user.titleBefore} {user.firstname} {user.lastname} {user.titleAfter}</h3>
                </div>
            </div>
            <div className="employee-card-right">
                <h4>Pracovní pozice</h4>
                {user && user.role !== "HQ_WITH_REPORT" && user.role !== "HEADQUARTERS" &&
                    <p>Regionální centrum: {mapRegionalCenter(user.rcId)}</p>
                }
                <p>Role: {userRoleToText(user.role)}</p>
                <h4 className="mt-10">Kontaktní informace</h4>
                <p>Email: <a href={`mailto:${userEmail}`}>{userEmail}</a></p>
                <p>Telefon: {user.phone}</p>
                <p>LinkedIn: <a href={user.linkedIn} target="_blank" rel="noopener noreferrer">{user.linkedIn}</a></p>
                <h4 className="mt-10">Stručný kariérní popis</h4>
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(user.bioText) }}></p>
            </div>
        </div>
    );
};

export default EmployeeCard;
