import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovFormControl,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon,
    GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import HorizontalBarChartCustomForPhases from "../dashboardComponents/HorizontalBarChartCustomForPhases";
import PieChartCustomPercent from "../dashboardComponents/PieChartCustomPercent";
import HorizontalBarChartCustomConsultations from "../dashboardComponents/HorizontalBarChartCustomConsultations";
import {mapDateFormat, parseJwt} from "../utils/utils";
import HorizontalBarChartCustom from "../dashboardComponents/HorizontalBarChartCustom";
import HorizontalBarChartCustomTowns from "../dashboardComponents/HorizontalBarChartCustomTowns";

export function DashboardContent() {

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");

    const currentDate = new Date();
    const pastDate = new Date(2024, 0); // January is month 0 in JavaScript Date
    const [regionalCenter, setRegionalCenter] = useState(() => {
        return (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? "0" : RCid;
    });
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [endMonth, setEndMonth] = useState(getMonthString(currentDate));
    const [startMonth, setStartMonth] = useState(getMonthString(pastDate));

    const [investmentsPhases, setInvestmentsPhases] = useState([])
    const [flatsPhases, setFlatsPhases] = useState([])
    const [projectsPhases, setProjectsPhases] = useState([])

    const [investmentsTypes, setInvestmentsTypes] = useState([])
    const [flatsTypes, setFlatsTypes] = useState([])
    const [projectsTypes, setProjectsTypes] = useState([])

    const [projectsMonths, setProjectsMonths] = useState([])
    const [consultationsMonths, setConsultationsMonths] = useState([])
    const [contactedTownsByMonth, setContactedTownsByMonth] = useState([]);

    const handleRegionalCenterChange = (event) => {
        setRegionalCenter(event.target.value);
    };

    const handleStartMonthChange = (event) => {
        setStartMonth(event.target.value);
    };

    const handleEndMonthChange = (event) => {
        setEndMonth(event.target.value);
    };

    function getMonthString(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month, so +1 is needed
        return `${year}-${month}`;
    }

    const jwt = localStorage.getItem("jwt")

    const typeIdMap = {
        '0': 'Není vybráno',
        '1': 'Nákup',
        '2': 'Stavba',
        '3': 'Rekonstrukce',
        '4': 'Mix'
    };

    const phases = [
        {name: 'Vize'},
        {name: 'Předprojektová příprava'},
        {name: 'Projektová fáze'},
        {name: 'Stavební povolení - příprava na realizaci'},
        {name: 'Realizace a provoz budovy'}
    ];

    function getTotal(flatData) {
        return flatData.reduce((total, current) => {
            return total + current.value;
        }, 0);
    }

    function getProjectCountByType(data) {
        // Inicializace objektu pro sledování počtu projektů podle typeId
        const projectCountByTypeName = {};
        let total = 0;

        // Procházení dat
        data.forEach(item => {
            item.projects.forEach(project => {
                const typeId = project.typeId;
                const typeName = typeIdMap[typeId] || 'Neznámý typ';

                // Inicializace počítadla pro konkrétní typeId, pokud ještě neexistuje
                if (!projectCountByTypeName[typeName]) {
                    projectCountByTypeName[typeName] = 0;
                }

                // Zvýšení počtu projektů pro příslušné typeId
                projectCountByTypeName[typeName]++;
                total++;
            });
        });
        // Vytvoření pole s výsledkem
        const result = Object.keys(projectCountByTypeName).map(typeName => ({
            name: typeName,  // Převod zpět na číslo
            value: projectCountByTypeName[typeName],
            total: total
        }));
        return result;
    }

    function getFlatsCountByType(data) {
        // Inicializace objektu pro sledování počtu bytů podle typeId
        const flatsCountByTypeName = {};
        let total = 0;

        // Procházení dat
        data.forEach(item => {
            item.projects.forEach(project => {
                const typeId = project.typeId;
                const typeName = typeIdMap[typeId] || 'Neznámý typ';
                const flats = (project.amountOfFlats || 0) + (project.amountOfOtherFlats || 0);

                // Inicializace počítadla pro konkrétní typeId, pokud ještě neexistuje
                if (!flatsCountByTypeName[typeName]) {
                    flatsCountByTypeName[typeName] = 0;
                }

                // Přičtení počtu bytů pro příslušné typeId
                flatsCountByTypeName[typeName] += flats;
                total += flats;
            });
        });

        // Vytvoření pole s výsledkem
        const result = Object.keys(flatsCountByTypeName).map(typeName => ({
            name: typeName,  // Převod zpět na číslo
            value: flatsCountByTypeName[typeName],
            total: total
        }));

        return result;
    }

    function getInvestmentByType(data) {
        // Inicializace objektu pro sledování velikosti investice podle typeId
        const investmentByTypeName = {};
        let total = 0

        // Procházení dat
        data.forEach(item => {
            item.projects.forEach(project => {
                const typeId = project.typeId;
                const typeName = typeIdMap[typeId] || 'Neznámý typ';
                const investment = parseFloat(project.investmentAmount) || 0;

                // Inicializace počítadla pro konkrétní typeId, pokud ještě neexistuje
                if (!investmentByTypeName[typeName]) {
                    investmentByTypeName[typeName] = 0;
                }

                // Přičtení velikosti investice pro příslušné typeId
                investmentByTypeName[typeName] += investment;
                total += investment
            });
        });


        // Vytvoření pole s výsledkem
        return Object.keys(investmentByTypeName).map(typeName => ({
            name: typeName,  // Převod zpět na číslo
            value: parseFloat(investmentByTypeName[typeName].toFixed(2)), // Zaokrouhlení na dvě desetinná místa
            total: total
        }));
    }

    function getInvestmentByPhase(data, phases) {
        let total = 0
        // Inicializace objektu pro sledování investic podle fází
        const phaseCounts = phases.reduce((acc, phase, index) => {
            acc[phase.name] = 0;  // Initialize count for each phase
            return acc;
        }, {});

        // Procházení dat
        data.forEach(item => {
            item.projects.forEach(project => {
                const phaseIndex = parseInt(project.phase, 10) - 1;  // Assuming phase is a string like "1", "2", etc.
                const phaseName = phases[phaseIndex]?.name;  // Get the corresponding phase name
                if (phaseName) {
                    phaseCounts[phaseName] += project.investmentAmount;
                    total += project.investmentAmount// Increment the count for the corresponding phase
                }
            });
        });
        let result = Object.entries(phaseCounts).map(([name, value]) => ({name, value}));
        result.forEach(item => {
            item.total = total
        })

        return result;
    }

    function countProjectsInPhases(data, phases) {
        let total = 0
        // Step 1: Initialize an object to hold the counts for each phase
        const phaseCounts = phases.reduce((acc, phase, index) => {
            acc[phase.name] = 0;  // Initialize count for each phase
            return acc;
        }, {});

        // Step 2: Iterate over the data and count projects by phase
        data.forEach(item => {
            item.projects.forEach(project => {
                const phaseIndex = parseInt(project.phase, 10) - 1;  // Assuming phase is a string like "1", "2", etc.
                const phaseName = phases[phaseIndex]?.name;  // Get the corresponding phase name
                if (phaseName) {
                    phaseCounts[phaseName] += 1;
                }
                total++;
            });
        });

        // Convert the phaseCounts object to an array of objects with phase name and count
        let result = Object.entries(phaseCounts).map(([name, value]) => ({name, value}));
        result.forEach(item => {
            item.total = total
        })

        return result;
    }

    function getFlatsByPhase(data, phases) {
        // Inicializace objektu pro uložení počtu bytů dle fází
        const flatsByPhase = phases.map(phase => ({name: phase.name, value: 0, total: 0}));
        let total = 0
        // Procházení dat
        data.forEach(item => {
            item.projects.forEach(project => {
                const phaseIndex = parseInt(project.phase, 10) - 1; // Fáze je 1-based index, takže odečteme 1
                const totalFlats = project.amountOfFlats + (project.amountOfOtherFlats || 0); // Celkový počet bytů

                // Přičteme počet bytů k odpovídající fázi
                if (flatsByPhase[phaseIndex]) {
                    flatsByPhase[phaseIndex].value += totalFlats;
                }
                total += totalFlats
            });
        });

        flatsByPhase.forEach(item => {
            item.total = total
        })

        return flatsByPhase;
    }

    function formatMonthYear(yearMonth) {
        const monthNames = [
            'Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen',
            'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'
        ];
        const [year, month] = yearMonth.split('-');
        return `${monthNames[parseInt(month) - 1]} ${year}`;
    }

    function getProjectDevelopment(data) {
        // Inicializace objektu pro sledování počtu projektů a projektů ve fázi realizace podle měsíců
        const projectDataByMonth = {};
        // Procházení dat
        data.forEach(item => {
            const yearMonth = item.yearMonth;

            // Inicializace měsíce v objektu, pokud ještě neexistuje
            if (!projectDataByMonth[yearMonth]) {
                projectDataByMonth[yearMonth] = {
                    totalCount: 0,
                    visionCount: 0,
                    preProjectCount: 0,
                    projectCount: 0,
                    buildingPermitCount: 0,
                    realizationCount: 0
                };
            }

            // Počet projektů a projektů ve fázi realizace v aktuálním měsíci
            item.projects.forEach(project => {
                projectDataByMonth[yearMonth].totalCount++;
                if (project.phase === 5) { // Zkontrolujeme, zda je projekt ve fázi realizace
                    projectDataByMonth[yearMonth].realizationCount++;
                } else if (project.phase === 1) {
                    projectDataByMonth[yearMonth].visionCount++;
                } else if (project.phase === 2) {
                    projectDataByMonth[yearMonth].preProjectCount++;
                } else if (project.phase === 3) {
                    projectDataByMonth[yearMonth].projectCount++;
                } else if (project.phase === 4) {
                    projectDataByMonth[yearMonth].buildingPermitCount++;
                }
            });

        });

        // Vytvoření pole s výsledkem
        const result = Object.keys(projectDataByMonth).map(month => ({
            name: formatMonthYear(month),
            Počet: projectDataByMonth[month].totalCount || 0, // Celkový počet projektů
            "Vize": projectDataByMonth[month].realizationCount || 0, // Počet projektů ve fázi realizace
            "Předprojektová příprava": projectDataByMonth[month].preProjectCount || 0,
            "Projektová fáze": projectDataByMonth[month].projectCount || 0,
            "Stavební povolení": projectDataByMonth[month].buildingPermitCount || 0,
        }));

        return result;
    }

    function getConsultationCountByMonth(data) {
        // Inicializace objektu pro sledování počtu konzultací podle měsíců
        const consultationCountsByMonth = {};

        // Procházení dat
        data.forEach(item => {
            const yearMonth = item.yearMonth;

            // Inicializace měsíce v objektu, pokud ještě neexistuje
            if (!consultationCountsByMonth[yearMonth]) {
                consultationCountsByMonth[yearMonth] = {
                    totalCount: 0,
                    agreementCount: 0,
                    nextConsultationCount: 0,
                    notInterestedCount: 0,
                    otherCount: 0
                };
            }

            // Počet konzultací v aktuálním měsíci
            const consultations = item.preProjectConsultations || [];
            consultations.forEach(consultation => {

                consultationCountsByMonth[yearMonth].totalCount++;
                if (consultation.result === 'AGREEMENT') {
                    consultationCountsByMonth[yearMonth].agreementCount++;
                } else if (consultation.result === 'NEXT_CONSULTATION_NEEDED') {
                    consultationCountsByMonth[yearMonth].nextConsultationCount++;
                } else if (consultation.result === 'NOT_INTERESTED') {
                    consultationCountsByMonth[yearMonth].notInterestedCount++;
                } else if (consultation.result === 'OTHER') {
                    consultationCountsByMonth[yearMonth].otherCount++;
                }
            });
        });

        // Vytvoření pole s výsledkem
        const result = Object.keys(consultationCountsByMonth).map(month => ({
            name: formatMonthYear(month),
            "Počet": consultationCountsByMonth[month].totalCount,
            'Dohoda o spolupráci obce s RC': consultationCountsByMonth[month].agreementCount,
            'Je nutná další konzultace': consultationCountsByMonth[month].nextConsultationCount,
            'Obec nemá zájem o spolupráci': consultationCountsByMonth[month].notInterestedCount,
            'Jiný výsledek': consultationCountsByMonth[month].otherCount,
        }));

        console.log(result)

        return result;
    }
    
    function getContactedTownsByMonth(data) {
        // Inicializace objektu pro sledování počtu konzultací podle měsíců
        const townsContactedCountByMonth = {};
        let aggregatedCount = 0;
        // Procházení dat
        data.forEach(item => {
            const yearMonth = item.yearMonth;

            // Inicializace měsíce v objektu, pokud ještě neexistuje
            if (!townsContactedCountByMonth[yearMonth]) {
                aggregatedCount += item.contactedTowns;
                townsContactedCountByMonth[yearMonth] = {
                    totalCount: item.contactedTowns,
                    aggregatedCount: aggregatedCount
                };
            }
        });
        console.log(townsContactedCountByMonth)

        // Vytvoření pole s výsledkem
        const result = Object.keys(townsContactedCountByMonth).map(month => ({
            name: formatMonthYear(month),
            "Počet": townsContactedCountByMonth[month].totalCount,
            'Agregovaný počet': townsContactedCountByMonth[month].aggregatedCount,
            'Celkový počet obcí': getCountOfTownsBasedOnRegionalCenter(regionalCenter),
        }));

        console.log(result)

        return result;
    }

    function getCountOfTownsBasedOnRegionalCenter(regionalCenter) {
        console.log(regionalCenter)
        let regionsCounts = {
            "Hlavní město Praha": 1,
            "Středočeský kraj": 1144,
            "Jihočeský kraj": 624,
            "Plzeňský kraj": 501,
            "Karlovarský kraj": 134,
            "Ústecký kraj": 354,
            "Liberecký kraj": 215,
            "Královéhradecký kraj": 448,
            "Pardubický kraj": 451,
            "Kraj Vysočina": 704,
            "Jihomoravský kraj": 673,
            "Olomoucký kraj": 402,
            "Zlínský kraj": 307,
            "Moravskoslezský kraj": 300
        }
        switch (regionalCenter) {
            case "1":
                return regionsCounts["Moravskoslezský kraj"];
            case "2":
                return regionsCounts["Středočeský kraj"] + regionsCounts["Hlavní město Praha"];
            case "3":
                return regionsCounts["Ústecký kraj"] + regionsCounts["Karlovarský kraj"];
            case "4":
                return regionsCounts["Královéhradecký kraj"] + regionsCounts["Liberecký kraj"];
            case "5":
                return regionsCounts["Pardubický kraj"] + regionsCounts["Kraj Vysočina"];
            case "6":
                return regionsCounts["Jihočeský kraj"] + regionsCounts["Plzeňský kraj"];
            case "7":
                return regionsCounts["Zlínský kraj"] + regionsCounts["Jihomoravský kraj"];
            case "8":
                return regionsCounts["Olomoucký kraj"];
            default:
                return 6258
        }

    }


    useEffect(() => {
        fetchData(startMonth, endMonth)
    }, [startMonth, endMonth, regionalCenter]);

    const fetchData = (startMonth, endMonth) => {
        setDataIsLoading(true);
        fetch(process.env.REACT_APP_API_URL + `/dashboard/data?from=${startMonth}&to=${endMonth}` + ((regionalCenter !== "" && regionalCenter !== "0") ? `&rcId=${regionalCenter}` : ""), {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                jsonData.sort((a, b) => {
                    const dateA = new Date(a.yearMonth + "-01");
                    const dateB = new Date(b.yearMonth + "-01");

                    return dateA - dateB;
                });

                setDataIsLoading(false);


                setInvestmentsPhases(getInvestmentByPhase(jsonData, phases))
                setProjectsPhases(countProjectsInPhases(jsonData, phases));
                setFlatsPhases(getFlatsByPhase(jsonData, phases));

                setInvestmentsTypes(getInvestmentByType(jsonData))
                setFlatsTypes(getFlatsCountByType(jsonData))
                setProjectsTypes(getProjectCountByType(jsonData))

                setProjectsMonths(getProjectDevelopment(jsonData))
                setConsultationsMonths(getConsultationCountByMonth(jsonData))
                setContactedTownsByMonth(getContactedTownsByMonth(jsonData));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };





    // Convert the processed data into an array for rendering


    return (
        <div>
            <div className="gov-container dashboard-container padding-top-0">
                <div className="gov-container__content gov-container__content">
                    <div
                        className="gov-title--beta">Dashboard: {(authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT') ? '' : localStorage.getItem("RCname")}</div>
                    <div className="gov-flexbox gov-flexbox--justify-start">
                        {(authority === 'HEADQUARTERS' || authority === 'HQ_WITH_REPORT') &&
                            <GovFormControl>
                                <GovFormSelect size="s" placeholder="Regionální centrum"
                                               onGov-change={handleRegionalCenterChange} value={regionalCenter}>
                                    <option disabled="disabled" value="">Vyberte regionální centrum</option>
                                    <option value="0">Všechna regionální centra</option>
                                    <option value="1">Moravskoslezský kraj</option>
                                    <option value="2">Středočeský kraj a Praha</option>
                                    <option value="3">Ústecký a Karlovarský kraj</option>
                                    <option value="4">Královehradecký a Liberecký kraj</option>
                                    <option value="5">Pardubický kraj a kraj Vysočina</option>
                                    <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                    <option value="7">Zlínský a Jihomoravský kraj</option>
                                    <option value="8">Olomoucký kraj</option>
                                </GovFormSelect>
                                <GovFormLabel slot="top" size="s">Regionální centrum*</GovFormLabel>
                            </GovFormControl>
                        }
                        <GovFormControl className="ml-5">
                            <GovFormInput value={startMonth} onGov-input={handleStartMonthChange} size="s"
                                          variant="secondary" input-type="month"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Měsíc od:</GovFormLabel>
                        </GovFormControl>
                        <GovFormControl className="ml-5">
                            <GovFormInput value={endMonth} onGov-input={handleEndMonthChange} size="s"
                                          variant="secondary" input-type="month"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Měsíc do:</GovFormLabel>
                        </GovFormControl>
                    </div>
                    <GovSpacer size="m"></GovSpacer>
                    {!dataIsLoading ?
                        <div>
                            <div className="flex-space-between flex-wrap row-gap">
                                <PieChartCustomPercent width={450} height={300} dy={150} data={projectsTypes}
                                                       title={"Počet projektů dle typu"}></PieChartCustomPercent>
                                <PieChartCustomPercent width={450} height={300} dy={150} data={flatsTypes}
                                                       title={"Počet bytů dle typu"}></PieChartCustomPercent>
                                <PieChartCustomPercent width={450} height={300} dy={150} data={investmentsTypes}
                                                       title={"Očekávaný objem investic dle typu"}></PieChartCustomPercent>
                                <PieChartCustomPercent width={450} height={300} dy={150} data={projectsPhases}
                                                       title={"Počet projektů dle fází"}></PieChartCustomPercent>
                                <PieChartCustomPercent width={450} height={300} dy={150} data={flatsPhases}
                                                       title={"Počet bytů dle fází"}></PieChartCustomPercent>
                                <PieChartCustomPercent width={450} height={300} dy={150} data={investmentsPhases}
                                                       title={"Očekávaný objem investic dle fází"}></PieChartCustomPercent>
                            </div>
                            <GovSpacer size="m"></GovSpacer>
                            <div className="flex-space-around">
                                <HorizontalBarChartCustomForPhases data={projectsMonths}
                                                                   title={"Vývoj počtu projektů za zadané období"}
                                                                   width={550} height={400}
                                ></HorizontalBarChartCustomForPhases>
                                <HorizontalBarChartCustomConsultations data={consultationsMonths}
                                                                       title={"Vývoj počtu konzultací za zadané období"}
                                                                       layout={"vertical"}
                                                                       width={550} height={400}
                                ></HorizontalBarChartCustomConsultations>
                            </div>
                        <div className="flex-space-around">
                                    <HorizontalBarChartCustomTowns data={contactedTownsByMonth}
                                                                           title={"Počet oslovených obcí"}
                                                                           layout={"vertical"}
                                                                           width={550} height={400}
                                    ></HorizontalBarChartCustomTowns>
                                </div>
                            </div>
                        :
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                     shape="text" animation="progress" count="1"
                                     height="700px"></GovSkeleton>}
                </div>

            </div>
        </div>
    )
}
