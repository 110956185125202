import {useParams} from "react-router-dom";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {UserCardsContent} from "../components/contentComponents/UserCardsContent";

export function UserCards() {
    return (
        <div>
            <Header></Header>
            <UserCardsContent></UserCardsContent>
            <Footer></Footer>
        </div>
    )
}
