import Navigation from "../Navigation";
import {
    GovBreadcrumbs, GovButton,
    GovFormControl,
    GovFormInput,
    GovFormSelect,
    GovIcon, GovPagination, GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import {convertIsoToCustomFormat, convertIsoToDateFormat, parseJwt} from "../utils/utils";
import {useEffect, useState} from "react";
import EmployeeCard from "../../pageElements/EmployeeCard";



export function ExternalConsultationsContent() {
    const [currentPage, setCurrentPage] = useState(1);
    const [letterChosen, setLetterChosen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [externalConsultations, setExternalConsultations] = useState(null);
    const [filteredItems, setFilteredItems] = useState();
    const [chosenRegionalCenter, setChosenRegionalCenter] = useState('0');

    const itemsPerPage = 20;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;

    const [sortTownsOrder, setSortTownsOrder] = useState('asc');

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const jwt = localStorage.getItem("jwt")

    const fetchData = (currentRequest) => {
        console.log(currentRequest)
        if (currentRequest === "?rcId=0") {
            currentRequest = "";
        }
        fetch(process.env.REACT_APP_API_URL + `/preProjectConsultations/external?userId=${localStorage.getItem("userId")}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                setExternalConsultations(jsonData);
                setFilteredItems(jsonData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSearchChange = (event) => {
        setCurrentPage(1)
        setSearchTerm(event.target.value);
        const filtered = externalConsultations.filter(consultation =>
            consultation.id.toString().includes(event.target.value.toString().toLowerCase()) ||
            consultation.town.name.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
        );
        setFilteredItems(filtered);

    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChosenRegionalCenter(selectedValue);
        setSearchTerm('');

        fetchData(selectedValue ? `?rcId=${selectedValue}` : "");

    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const handleLetterClick = (letter) => {
        setCurrentPage(1)
        if(letter === ''){
            setLetterChosen(false)
        }
        else {
            setLetterChosen(true)
        }
        setSearchTerm(letter);
        if(externalConsultations.length > 0) {
            const filtered = externalConsultations.filter(project =>
                project.lastname.toLowerCase().startsWith(letter.toLowerCase())
            );
            setFilteredItems(filtered);
        }

    };

    useEffect(() => {
        fetchData();
    }, []);




    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    return (
        <div className="mb-100">
            <Navigation active={"user-cards"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Externí konzultace</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-title--beta">Přehled externích konzultací: </div>

                        <div className="gov-flexbox search-row-width">
                            <GovFormInput onGov-input={handleSearchChange}
                                          value={searchTerm}
                                          placeholder="Zadejte kód konzultace nebo název obce" size="m"
                                          variant="secondary">
                                <GovIcon type="basic" name="search" slot="right-icon"></GovIcon>
                            </GovFormInput>

                        </div>

                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ?
                            <table cellSpacing="0" cellPadding="0">
                                <thead>
                                <tr>
                                    <th>Číslo konzultace
                                    </th>
                                    <th>Obec
                                    </th>
                                    <th>Datum a čas začátku konzultace
                                    </th>
                                    <th>Datum a čas konce konzultace
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentItems.map((row, index) => (
                                    <tr key={index}>
                                        <td className="first"><a href={`/new-consultation/detail/` + row.id}>{row.id}</a>
                                        </td>
                                        <td>{row.town.name} ({row.town.id})</td>
                                        <td>{convertIsoToDateFormat(row.start)}</td>
                                        <td>{convertIsoToCustomFormat(row.end)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="500px" height="200px"></GovSkeleton>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}
