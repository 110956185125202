import React, { Component } from "react";
import "react-quill/dist/quill.snow.css";
import "../components/css/rich-text-editor.css"; // Import your custom CSS file after the snow theme
import ReactQuill from "react-quill";



class RichTextEditor extends Component {

    componentDidMount() {
        const input = document.querySelector("input[data-link]");
        console.log("input", input);
        if (input) {
            input.dataset.link = "https://www.site.com";
            input.placeholder = "https://www.site.com";
        }
    }

    modules = {
        toolbar: [
            ["bold", "italic", "underline", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"]
        ]
    };

    formats = [
        "bold", "italic", "underline", "blockquote",
        "list", "bullet", "indent",
        "link"
    ];

    render() {
        console.log("this.props", this.props);
        const { value, setValue } = this.props;


        return (
            <div className="text-editor">
                <ReactQuill
                    modules={this.modules}
                    formats={this.formats}
                    value={value}
                    onChange={setValue}
                    style={{ height: "200px" }}
                />
            </div>
        );
    }
}

export default RichTextEditor;
