import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {NewLandContent} from "../components/contentComponents/Land/NewLandContent";

export function NewLand() {
    return (
        <div>
            <Header></Header>
            <NewLandContent></NewLandContent>
            <Footer></Footer>
        </div>
    )
}
