import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs, GovButton, GovDropdown, GovFormCheckbox,
    GovFormControl, GovFormFile, GovFormGroup, GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon, GovLoading, GovMessage, GovModal, GovPrompt, GovSkeleton, GovSpacer, GovTabs, GovTabsItem,
    GovTile,
    GovTiles
} from "@gov-design-system-ce/react";
import {useEffect, useLayoutEffect, useState} from "react";
import "../css/report-statistics.css";
import {
    convertIsoToDateFormat,
    convertIsoToCustomFormat,
    parseJwt,
    convertIsoToLocalDate,
    convertIsoToTime, mapTimeSheetType, getIcon, mapDateFormat
} from "../utils/utils";
import Select, {components} from "react-select";
import {MenuList} from "./MenuList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TripReportDetailModal} from "./TripReportDetailModal";
import {useNavigate} from "react-router-dom";
import {RoadCompensationModal} from "./RoadCompensationModal";

export function RoadCompensationOneMonthContent({monthReportId}) {
    const jwt = localStorage.getItem("jwt")
    const [user, setUser] = useState(null)

    let [roadCompensations, setRoadCompensations] = useState(null)


    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const currentlyLoggedUserId = localStorage.getItem("userId");

    const RCid = localStorage.getItem("RCid");

    const [roadCompensationReport, setRoadCompensationReport] = useState(null)


    const fetchCarsSFPI = () => {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setCarsSFPI(jsonData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };

    function fetchPrivateCars() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private?userId=${user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setCarsArray(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }


    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 300;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [currentRoadCompensationId, setCurrentRoadCompensationId] = useState(null);
;
    const [filteredItems, setFilteredItems] = useState(null);
    const [modalTimesheetOpen, setModalTimesheetOpen] = useState(false);

    const [createTimesheet, setCreateTimesheet] = useState(false);

    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])


    function openModalTimesheet() {
        setModalTimesheetOpen(true)
    }


    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;


    const filterItems = () => {
        if (roadCompensations) {
            // Filter by type
            let filtered = roadCompensations.filter(item => filtersByTimesheetType[item.workReport.type.toString()]);
            setFilteredItems(filtered);
        }
    };

    function fetchCurrentWorkTripCompensation(id) {
        let roadCompensation;
        fetch(process.env.REACT_APP_API_URL + `/workTrips/${id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            roadCompensation = jsonData;

            setExpenses(roadCompensation.expenses)
            setFromPlace(roadCompensation.from)
            setToPlace(roadCompensation.to)
            setRoadDistance(roadCompensation.distance)
            setFoodCountProvided(roadCompensation.mealsCount)
            setRoadCompensationDate(convertIsoToLocalDate(roadCompensation.workReport.start))
            setRoadCompensationStartTime(convertIsoToTime(roadCompensation.workReport.start))
            setRoadCompensationEndTime(convertIsoToTime(roadCompensation.workReport.end))
            setRoadCompensationDuration(calculateHoursWorkedForEdit(roadCompensation.workReport.start, roadCompensation.workReport.end))
            setWorkReportType(roadCompensation.workReport.type)
            setTransportType(roadCompensation.transportType)
            console.log(roadCompensation.workReport.town ? roadCompensation.workReport.town.id : '')



            if (roadCompensation.privateVehicle) {
                setSelectedCarId(roadCompensation.privateVehicle.id);
                setFuelConsumption(roadCompensation.privateVehicle.consumption);
            } else if (roadCompensation.companyVehicle) {
                setSelectedCarId(roadCompensation.companyVehicle.id);
                setFuelConsumption(roadCompensation.companyVehicle.consumption);
            } else {
                setSelectedCarId(null); // or any default value
                setFuelConsumption(0); // or any default value
            }
            setRoadCompensationDescription(roadCompensation.description)

            setSelectedProjectId(roadCompensation.workReport.project ? roadCompensation.workReport.project.id : '')
            setSelectedVillageId(roadCompensation.workReport.town ? roadCompensation.workReport.town.id : '')

            setModalTimesheetOpen(true)
            setSelectedProjectIdInvalid(false)
            setSelectedVillageIdInvalid(false)
            setRoadCompensationDateInvalid(false)
            setRoadCompensationStartTimeInvalid(false)
            setRoadCompensationEndTimeInvalid(false)
            setRoadCompensationDescriptionInvalid(false)
            setRoadCompensationTimesRangeInvalid(false)
            setRoadCompensationFromPlaceInvalid(false)
            setRoadCompensationToPlaceInvalid(false)
            setRoadCompensationValid(true)

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }


    const openEditRoadCompensationModal = (event) => {
        setCurrentRoadCompensationId(event.target.value)
        fetchCurrentWorkTripCompensation(event.target.value)
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const closeEditModal = (event) => {
        setCurrentRoadCompensationId(null)
        setNameOfExpense("")
        setCostOfExpense("")
        setTransportType("0")
        setWorkReportType("6")
        setSelectedProjectId("")
        setSelectedVillageId("")
        setFromPlace("")
        setToPlace("")
        setRoadCompensationDate("")
        setRoadCompensationStartTime("")
        setRoadCompensationEndTime("")
        setFoodCountProvided("")

        setRoadCompensationDescription("")
        setRoadCompensationDuration(null)

        setSelectedProjectId("")
        setSelectedVillageId("")
        setCreateTimesheet(false)
        setPassengerInvalid(false)
        setModalTimesheetOpen(false);
        setSelectedProjectIdInvalid(true)
        setSelectedVillageIdInvalid(true)
        setRoadCompensationStartTimeInvalid(true)
        setRoadCompensationEndTimeInvalid(true)
        setRoadCompensationDescriptionInvalid(true)
        setRoadCompensationTimesRangeInvalid(true)
        fetchMonthRoadCompensations()
    }

    function calculateCompensation(){
        const fuelPrice = 38.2
        const usageCompensation = 5.6

        return (roadDistance*usageCompensation + roadDistance*(fuelPrice*fuelConsumption/100)).toFixed(2)
    }
    function putRoadCompensation() {
        fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                "description": roadCompensationDescription,
                "note": "",
                "transportType": transportType,
                "from": fromPlace,
                "to": toPlace,
                "mealsCount": foodCountProvided,
                "distance": roadDistance,
                "privateVehicleId": transportType === "2" ? selectedCarId : null,
                "companyVehicleId": transportType === "3" ? selectedCarId : null,
                "start": roadCompensationDate + "T" + roadCompensationStartTime + ":00",
                "end": roadCompensationDate + "T" + roadCompensationEndTime + ":00",
                "workReportType": workReportType,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId=== "" ? null : selectedVillageId,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthRoadCompensations()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        closeEditModal()
    }

    function postRoadCompensation() {
        fetch(process.env.REACT_APP_API_URL + "/workTrips", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "description": roadCompensationDescription,
                "note": "",
                "transportType": transportType,
                "from": fromPlace,
                "to": toPlace,
                "mealsCount": foodCountProvided,
                "distance": roadDistance,
                "privateVehicleId": transportType === "2" ? selectedCarId : null,
                "companyVehicleId": transportType === "3" ? selectedCarId : null,
                "start": roadCompensationDate + "T" + roadCompensationStartTime + ":00",
                "end": roadCompensationDate + "T" + roadCompensationEndTime + ":00",
                "workReportType": workReportType,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId=== "" ? null : selectedVillageId,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthRoadCompensations()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });

        closeEditModal()
    }



    const [transportType, setTransportType] = useState("0");
    const [workReportType, setWorkReportType] = useState("6");
    const [carsSFPI, setCarsSFPI]=useState([])
    const [cars, setCarsArray]=useState([])
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedCarId, setSelectedCarId]=useState('')
    const [fuelConsumption, setFuelConsumption]=useState(0)


    const [selectedVillageId, setSelectedVillageId] = useState('')
    const [roadCompensationDate, setRoadCompensationDate] = useState('')
    const [fromPlace, setFromPlace] = useState('')
    const [toPlace, setToPlace] = useState('')
    const [roadDistance, setRoadDistance]=useState(0)
    const [dateFromFilter, setDateFromFilter] = useState(new Date())
    const [dateToFilter, setDateToFilter] = useState(new Date())
    const [roadCompensationStartTime, setRoadCompensationStartTime] = useState('')
    const [roadCompensationEndTime, setRoadCompensationEndTime] = useState('')
    const [roadCompensationDuration, setRoadCompensationDuration] = useState(null)
    const [roadCompensationDescription, setRoadCompensationDescription] = useState('')
    const [foodCountProvided, setFoodCountProvided] = useState('')
    const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
    const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
    const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
    const [roadCompensationFromPlaceInvalid, setRoadCompensationFromPlaceInvalid] = useState(true)
    const [roadCompensationToPlaceInvalid, setRoadCompensationToPlaceInvalid] = useState(true)
    const [foodCountProvidedInvalid, setFoodCountProvidedInvalid] = useState(true)
    const [roadCompensationDateInvalid, setRoadCompensationDateInvalid] = useState(true)
    const [roadCompensationStartTimeInvalid, setRoadCompensationStartTimeInvalid] = useState(true)
    const [roadCompensationEndTimeInvalid, setRoadCompensationEndTimeInvalid] = useState(true)
    const [roadCompensationDescriptionInvalid, setRoadCompensationDescriptionInvalid] = useState(true)
    const [roadCompensationTimesRangeInvalid, setRoadCompensationTimesRangeInvalid] = useState(true)

    const [roadCompensationValid, setRoadCompensationValid] = useState(false)

    const handleFromPlaceChange = (event) => {
        if(event.target.value === ""){
            setRoadCompensationFromPlaceInvalid(false)
        }
        else{
            setRoadCompensationFromPlaceInvalid(true)
        }
        setFromPlace(event.target.value);
    }

    const handleFoodCountProvidedChange = (event) => {
        if (event.target.value === "") {
            setFoodCountProvidedInvalid(false)
        }
        else {
            setFoodCountProvidedInvalid(true)
        }
        setFoodCountProvided(event.target.value);
    }

    const handleToPlaceChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationToPlaceInvalid(false)
        }
        else {
            setRoadCompensationToPlaceInvalid(true)
        }
        setToPlace(event.target.value);
    }

    const handleMeanOfTransportSelectChange = (event) => {
        if(event.target.value === ""){
            setTimesheetTypeInvalid(false)
        }
        else{
            setTimesheetTypeInvalid(true)
        }
        setTransportType(event.target.value);
    };

    const handleVillageIdChange = (event) => {
        if (event.target === "") {
            setSelectedVillageIdInvalid(true)
        } else {
            setSelectedVillageIdInvalid(false)
        }
        setSelectedVillageId(event.value);
    };

    const handleRoadDistanceChange = (event) => {
        setRoadDistance(event.target.value)
    }

    const handleDateChange = (event) => {
        setRoadCompensationDate(event.target.value);
        setRoadCompensationDuration(calculateRoadTripDurationForDateChange(event.target.value, roadCompensationStartTime, roadCompensationEndTime));
    };

    const handleDateFromFilterChange = (event) => {
        setDateFromFilter(event.target.value);
    }

    const handleDateToFilterChange = (event) => {
        setDateToFilter(event.target.value);
    }

    const handleStartTimeChange = (event) => {
        setRoadCompensationStartTime(event.target.value);
        setRoadCompensationDuration(calculateRoadTripDuration(event.target.value, roadCompensationEndTime));
    }

    const handleWorkReportTypeChange = (event) => {
        setWorkReportType(event.target.value);
    }

    const handleEndTimeChange = (event) => {
        setRoadCompensationEndTime(event.target.value);
        setRoadCompensationDuration(calculateRoadTripDuration(roadCompensationStartTime, event.target.value));
    }

    const handleDescriptionChange = (event) => {
        setRoadCompensationDescription(event.target.value);
    }

    const handleCarIdChange = (event) => {

        setSelectedCarId(event.target.value);
    };

    const handleProjectIdChange = (event) => {
        if (event.value === "") {
            setSelectedProjectIdInvalid(true)
        } else {
            setSelectedProjectIdInvalid(false)
        }
        setSelectedProjectId(event.value);
    };

    function calculateHoursWorkedForEdit(startTime, endTime) {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setRoadCompensationTimesRangeInvalid(true)
            return 0
        }
        else {
            setRoadCompensationTimesRangeInvalid(false)
            return result
        }
    }

    function calculateRoadTripDurationForDateChange(date, startTime, endTime) {
        const start = new Date(`${date}T${startTime}:00`);
        const end = new Date(`${date}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setRoadCompensationTimesRangeInvalid(true)
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.25, 0.50, 0.75];

            if (!validEndings.includes(decimalPart)) {
                setRoadCompensationTimesRangeInvalid(true);
                return result.toFixed(2);
            } else {
                setRoadCompensationTimesRangeInvalid(false);
                return result.toFixed(2);
            }
        }
    }

    function calculateRoadTripDuration(startTime, endTime) {
        const start = new Date(`${roadCompensationDate}T${startTime}:00`);
        const end = new Date(`${roadCompensationDate}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setRoadCompensationTimesRangeInvalid(true)
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.25, 0.50, 0.75];

            if (!validEndings.includes(decimalPart)) {
                setRoadCompensationTimesRangeInvalid(true);
                return result.toFixed(2);
            } else {
                setRoadCompensationTimesRangeInvalid(false);
                return result.toFixed(2);
            }
        }
    }

    const calculateHoursWorkedFromIso = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const differenceInMilliseconds = endDate - startDate;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        return differenceInHours.toFixed(2); // Returns the difference in hours, rounded to 2 decimal places
    };


    const [dateRange, setDateRange] = useState('');
    const [labels, setLabels] = useState({
        today: '',
        yesterday: '',
        thisWeek: '',
        lastWeek: '',
        thisMonth: '',
        lastMonth: '',
        custom: 'Volný výběr'
    });
    const generateLabels = () => {
        const today = new Date();
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        const formatDate = (date) => date.toLocaleDateString('cs-CZ', {day: 'numeric', month: 'long', year: 'numeric'});

        const newLabels = {
            lastMonth: `Minulý měsíc (${formatDate(lastMonthStart)} - ${formatDate(lastMonthEnd)})`,
        };

        setLabels(newLabels);
        setDateRange(newLabels.today);
    };

    const handleRangeOfDatesChange = (event) => {
        const selectedRange = event.target.value;
        setDateRange(selectedRange);

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const thisWeekStart = new Date(today);
        thisWeekStart.setDate(today.getDate() - today.getDay() +1);

        const lastWeekStart = new Date(thisWeekStart);
        lastWeekStart.setDate(thisWeekStart.getDate() - 7);

        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

        switch (selectedRange) {
            case labels.today:
                setDateFromFilter(today.toISOString().split('T')[0]);
                setDateToFilter(today.toISOString().split('T')[0]);
                break;
            case labels.yesterday:
                setDateFromFilter(yesterday.toISOString().split('T')[0]);
                setDateToFilter(yesterday.toISOString().split('T')[0]);
                break;
            case labels.thisWeek:
                setDateFromFilter(thisWeekStart.toISOString().split('T')[0]);
                setDateToFilter(today.toISOString().split('T')[0]);
                break;
            case labels.lastWeek:
                setDateFromFilter(lastWeekStart.toISOString().split('T')[0]);
                setDateToFilter(thisWeekStart.toISOString().split('T')[0]);
                break;
            case labels.thisMonth:
                setDateFromFilter(thisMonthStart.toISOString().split('T')[0]);
                setDateToFilter(today.toISOString().split('T')[0]);
                break;
            case labels.lastMonth:
                setDateFromFilter(lastMonthStart.toISOString().split('T')[0]);
                setDateToFilter(lastMonthEnd.toISOString().split('T')[0]);
                break;
            case labels.custom:
                setDateFromFilter(null);
                setDateToFilter(null);
                break;
            default:
                break;
        }
    };

    let request = "";
    const fetchProjects = () => {
        if (authority !== "HEADQUARTERS" && authority !== "HQ_WITH_REPORT") {
            request = "?regionalCenterId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const projectsArray = jsonData.map(project => {
                    return {value: project.id, label: project.name + " (" + project.id + ")"}
                })
                setProjects(projectsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const fetchVillages = () => {
        if (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") {
            request = "/towns";
        } else {
            request = "/rc/" + RCid + "/towns";
        }
        if(localStorage.getItem("towns") === null) {
            fetch(process.env.REACT_APP_API_URL + request, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setVillages(jsonData);
                    localStorage.setItem("towns", JSON.stringify(jsonData))
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }else {
            setVillages(JSON.parse(localStorage.getItem("towns")))
        }
    };

    useEffect(() => {
        if (transportType === "2" || transportType === 2) {
            fetchPrivateCars();
        }
        else if (transportType === "3" || transportType === 3) {
            fetchCarsSFPI();
        }
    }, [transportType]);

    useEffect(() => {
        if (workReportType === "2" || workReportType === 2) {
            fetchVillages();
        } else if (workReportType === "3" || workReportType === 3) {
            fetchProjects();
        }
    }, [workReportType]);

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };

    const [expenses, setExpenses] = useState([]);
    const [sortDateOrder, setSortDateOrder] = useState('asc');

    const sortTimesheetsByDate = () => {
        if (roadCompensations) {
            let sortedTimesheets = [...filteredItems];
            sortedTimesheets.sort((a, b) => {
                const dateA = new Date(a.workReport.start);
                const dateB = new Date(b.workReport.start);
                return sortDateOrder === 'asc' ? dateA - dateB : dateB - dateA;
            });
            setFilteredItems(sortedTimesheets);
        }
    };



    function checkErrorsTimesheet() {
        const formStartTime = new Date(`${roadCompensationDate}T${roadCompensationStartTime}:00`);
        const formEndTime = new Date(`${roadCompensationDate}T${roadCompensationEndTime}:00`);
        let hasMatchingActivity
        if (currentItems) {
            hasMatchingActivity = currentItems.some(item => {
                const itemStartTime = new Date(item.workReport.start);
                const itemEndTime = new Date(item.workReport.end);
                return itemStartTime.getTime() === formStartTime.getTime() &&
                    itemEndTime.getTime() === formEndTime.getTime() &&
                    item.workReport.id !== currentRoadCompensationId;
            });
        }
        if (hasMatchingActivity) {
            setActivityCollision(true);
        } else {
            setActivityCollision(false);
        }
        let ready = !timesheetTypeInvalid && !roadCompensationDateInvalid
            && !roadCompensationStartTimeInvalid && !roadCompensationEndTimeInvalid && !roadCompensationDescriptionInvalid
            && !roadCompensationTimesRangeInvalid && !isSelectedCarInvalid && !roadCompensationFromPlaceInvalid && !roadCompensationToPlaceInvalid
            && !hasMatchingActivity
        console.log(timesheetTypeInvalid, roadCompensationValid, roadCompensationDateInvalid, roadCompensationStartTimeInvalid, roadCompensationEndTimeInvalid, roadCompensationDescriptionInvalid, roadCompensationTimesRangeInvalid, isSelectedCarInvalid, roadCompensationFromPlaceInvalid, roadCompensationToPlaceInvalid, hasMatchingActivity)
        if (workReportType === "2") {
            console.log("checking village")
            ready = ready && !selectedVillageIdInvalid
        } else if (workReportType === "3") {
            ready = ready && !selectedProjectIdInvalid
        }
        setRoadCompensationValid(ready)
    }

    const [filtersByTimesheetType, setFiltersByTimesheetType] = useState({
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "6": true,
        "7": true,
    });

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    function openDeleteModal(event) {
        setCurrentRoadCompensationId(event.target.value)
        setDeleteModalOpen(true)
    }

    function closeDeleteModal() {
        setCurrentRoadCompensationId(null)
        setDeleteModalOpen(false)
    }

    function deleteRoadCompensation(event){
        fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.status === 204) {
                    fetchMonthRoadCompensations()
                } else {
                    return response.json().then(data => {
                        throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        closeDeleteModal()
    }
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setFiltersByTimesheetType(prevFilters => ({
            ...prevFilters,
            [value]: checked
        }));
    };

    const calculateTotalWorkHours = () => {
        if (currentItems) {
            return currentItems.reduce((total, item) => {
                if (item.type !== -1) {
                    const startDate = new Date(item.workReport.start);
                    const endDate = new Date(item.workReport.end);
                    const startDay = startDate.getDay();
                    const endDay = endDate.getDay();

                    // Check if the start and end dates are not weekends (Saturday or Sunday)
                    if (startDay !== 0 && startDay !== 6 && endDay !== 0 && endDay !== 6) {
                        const hoursWorked = calculateHoursWorkedFromIso(item.workReport.start, item.workReport.end);
                        return total + parseFloat(hoursWorked);
                    }
                }
                return total;
            }, 0).toFixed(2);
        }
        return 0;
    };



    useEffect(() => {
        checkErrorsTimesheet()
    }, [transportType, roadCompensationDate, roadCompensationStartTime, roadCompensationEndTime, roadCompensationDescription, selectedProjectId, selectedVillageId, selectedCarId,
        fromPlace, toPlace, roadDistance, roadCompensationDuration, workReportType])

    useEffect(() => {
        sortTimesheetsByDate();
        // eslint-disable-next-line no-use-before-define
    }, [sortDateOrder]);


    // useEffect(() => {
    //     filterItemsByDateRange()
    // }, [timesheets,dateFromFilter, dateToFilter]);
    //
    // useEffect(() => {
    //     filterItemsByType();
    // }, [filtersByTimesheetType, timesheets]);

    const filterItemsByType = () => {
        if (roadCompensations) {
            const filtered = roadCompensations.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    useEffect(() => {
        filterItems();
    }, [roadCompensations, dateFromFilter, dateToFilter, filtersByTimesheetType]);


    const [pageView, setPageView] = useState("Cesta");
    const setTypeOfView = (event) => {
        if (event.detail.label === "Cesta") {
            setPageView("Cesta")
        }
        else if (event.detail.label === "Další náklady") {
            setPageView("Další náklady")
        }
    }

    // Step 1: Define State Variables

    const [nameOfExpense, setNameOfExpense] = useState("");
    const [costOfExpense, setCostOfExpense] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    const handleNameChange = (event) => setNameOfExpense(event.target.value);
    const handleCostChange = (event) => setCostOfExpense(event.target.value);

    // Step 4: Validation
    const validateForm = () => {
        if (nameOfExpense && costOfExpense ) {
            setFormValid(true);
            setErrorMessage("");
        } else {
            setFormValid(false);
            setErrorMessage("Prosím vyplňte všechny povinné položky.");
        }
    };
    useEffect(() => {
        validateForm();
    }, [nameOfExpense, costOfExpense]);

    const downloadTripsMonthReport = (event) => {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/tripsReports/${monthReportId}/export`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = user.firstname + " " + user.lastname + " cestovní náhrady za " + roadCompensationReport.date  ; // Replace with the desired file name and extension
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setDocumentIsDownloading(false)
            })
            .catch(error => console.error('There was a problem with the fetch operation:', error));
    };

    // Step 5: Post Data
    const postWorkTripExpense = () => {
        if (!formValid) {
            setErrorMessage("Prosím vyplňte všechny povinné položky.");
            return;
        }


        fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}/expenses`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("jwt")}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "workTrip": currentRoadCompensationId,
                "name": nameOfExpense,
                "cost": costOfExpense,
                "documentId": null
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("Expense submitted successfully:", data);
                // Reset form
                setCurrentRoadCompensationId("")
                setNameOfExpense("");
                setCostOfExpense("");
                setFormValid(false);
            })
            .catch(error => {
                console.error('Error submitting expense:', error);
                setErrorMessage("Nastala chyba zkuste to prosím znovu.");
            });
    };
    const [documentIsLoading, setDocumentIsLoading] = useState(false);
    const [documentIsDownloading, setDocumentIsDownloading] = useState(false);
    function uploadDocument(file, expenseId) {
        setDocumentIsLoading(true)
        const formData = new FormData();
        formData.append('file', file);


        fetch(`${process.env.REACT_APP_API_URL}/workTrips/${currentRoadCompensationId}/expenses/${expenseId}/invoice/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchCurrentWorkTripCompensation(currentRoadCompensationId)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function fetchExpenseDocument(id, name) {
        fetch(`${process.env.REACT_APP_API_URL}/workTrips/${currentRoadCompensationId}/expenses/${id}/invoice/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }
    const [note, setNote] = useState('')

    function handleNoteChange(event) {
        setNote(event.target.value)
    }

    const [rejectModalOpen, setRejectModalOpen] = useState(false)

    function closeRejectModal() {
        setRejectModalOpen(false)
    }

    const fetchMonthRoadCompensations = () => {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/tripsReports/${monthReportId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                setRoadCompensationReport(jsonData);
                setUser(jsonData.user)
                const sortedData = jsonData.workTrips.sort((a, b) => new Date(a.workReport.start) - new Date(b.workReport.start));
                console.log(sortedData)
                setRoadCompensations(sortedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };

    function approveRoadCompensations() {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/tripsReports/${monthReportId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "state": (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? "APPROVED_BY_HQ" : "APPROVED_BY_SUPERVISOR",
                "note": note
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                fetchMonthRoadCompensations()
            })
            .catch(error => {
                return error.message;
            });
    }

    function declineRoadCompensations() {
        fetch(process.env.REACT_APP_API_URL + `/monthReports/tripsReports/${monthReportId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "state": (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") ? "REJECTED_BY_HQ" : "REJECTED_BY_SUPERVISOR",
                "note": note
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                closeRejectModal()
                fetchMonthRoadCompensations()

            })
            .catch(error => {
                return error.message;
            });
    }

    const stateMessages = {
        CREATED: {
            message: "Tyto cestovní náhrady jsou schvalovány vedoucím regionálního centra."
        },
        APPROVED_BY_SUPERVISOR: {
            message: "Tyto cestovní náhrady byly schváleny vedoucím regionálního centra.",
            style: { color: 'green' }
        },
        APPROVED_BY_HQ: {
            message: "Tyto cestovní náhrady byly schváleny centrálou.",
            style: { color: 'green' }
        },
        REJECTED_BY_SUPERVISOR: {
            message: "Tyto cestovní náhrady byly zamítnuty vedoucím regionálního centra.",
            style: { color: 'red' },
            note: true
        },
        REJECTED_BY_HQ: {
            message: "Tyto cestovní náhrady byly zamítnuty centrálou.",
            style: { color: 'red' },
            note: true
        }
    };

    const [roadCompensationViewModalOpen, setRoadCompensationViewModalOpen] = useState(false)

    function openRoadCompensationViewModal(event) {
        setCurrentRoadCompensationId(event.target.value)
        setRoadCompensationViewModalOpen(true)
    }

    function closeRoadCompensationViewModal() {
        setRoadCompensationViewModalOpen(false)
    }

    const [addExpensesAfterCreation, setAddExpensesAfterCreation] = useState(false)
    const handleSettingPageView = (event) => {
        setPageView(event.detail.label)
    }
    const [isSelectedCarInvalid, setIsSelectedCarInvalid] = useState(false);

    const navigate = useNavigate();

    const [activityCollision, setActivityCollision] = useState(false)

    const [passengerInvalid, setPassengerInvalid] = useState(false)

    function goToCarAddPage() {
        navigate("/profile/cars/add")
    }

    function goToCarDetailPage(id) {
        navigate(`/profile/cars/${id}`)
    }

    useLayoutEffect(() => {
        generateLabels();
        fetchMonthRoadCompensations();
        fetchVillages();
    }, []);

    useEffect(() => {
        fetchMonthRoadCompensations();
    }, [modalTimesheetOpen]);

    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    return (
        <div className="">
            <Navigation active={"road-compensation"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        {(user && authority !== "RC_EMPLOYEE") && <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href={`/user-management/month-reports/${user.id}`}>Přehled timesheetů a cestovních náhrad
                                ({user.firstname + " " + user.lastname})</a>
                        </li>
                        }
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>{authority !== "RC_EMPLOYEE" ? "Schválení" : "Detail"} cestovních náhrad</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container vh-80">
                    <div className="gov-container__content">
                        {(user && authority !== "RC_EMPLOYEE") ?
                            <div className="gov-title--beta">Schválení cestovních náhrad
                                ({user.firstname + " " + user.lastname})</div>
                            :
                            <div className="gov-title--beta">Detail cestovních náhrad</div>
                        }
                        <div className="gov-flexbox gov-flexbox--justify-start align-items-normal">
                            <div>

                                {/*<GovFormControl>*/}
                                {/*    <GovFormSelect size="s" variant="secondary" value={dateRange}*/}
                                {/*                   onGov-change={handleRangeOfDatesChange}*/}
                                {/*                   disabled="true">*/}
                                {/*        {Object.entries(labels).map(([key, value]) => (*/}
                                {/*            <option key={key} value={value}>{value}</option>*/}
                                {/*        ))}*/}
                                {/*    </GovFormSelect>*/}
                                {/*    <GovFormLabel slot="top" size="s">Období k zobrazení*</GovFormLabel>*/}
                                {/*</GovFormControl>*/}
                                {roadCompensationReport &&
                                <div>
                                    <h3>Cestovní náhrady za období</h3>
                                    <p>{mapDateFormat(roadCompensationReport.date)}</p>
                                </div>
                                }
                                <div>
                                    {roadCompensationReport && stateMessages[roadCompensationReport.state] && (
                                        <div>
                                            <div style={stateMessages[roadCompensationReport.state].style}>
                                                {stateMessages[roadCompensationReport.state].message}
                                            </div>
                                            {stateMessages[roadCompensationReport.state].note && (
                                                <p>Důvod zamítnutí: {roadCompensationReport.note}</p>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div>
                                    {roadCompensationReport && roadCompensationReport.state === "REJECTED" && (
                                        <div>
                                            <div style={{color: 'red'}}>Tyto cestovní náhrady byly zamítnuty.</div>
                                            <p>Důvod zamítnutí: {roadCompensationReport.note}</p>
                                        </div>
                                    )}
                                    {roadCompensationReport && roadCompensationReport.state === "APPROVED" && (
                                        <div style={{color: 'green'}}>Tyto cestovní náhrady byly schváleny.</div>
                                    )}
                                </div>
                                {roadCompensationReport &&
                                    ((authority === "RC_LEADER" && currentlyLoggedUserId !== roadCompensationReport.user.id && (roadCompensationReport.state === "CREATED" || roadCompensationReport.state === "REJECTED_BY_HQ"))
                                        || ((authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") && (roadCompensationReport.state === "CREATED" || roadCompensationReport.state === "APPROVED_BY_SUPERVISOR"
                                        ||  roadCompensationReport.state === "REJECTED_BY_HQ" || roadCompensationReport.state === "APPROVED_BY_HQ")))
                                    &&
                                    <div>
                                        <GovButton className="gov-flexbox--justify-center"
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="solid"
                                                   onGov-click={() => {
                                                       approveRoadCompensations()
                                                   }} size="m">Schválit cestovní náhrady
                                        </GovButton>
                                        <GovButton className="ml-5 gov-flexbox--justify-center"
                                                   wcag-label="What exactly happens when you click?" variant="error"
                                                   type="solid"
                                                   onGov-click={() => {
                                                       setRejectModalOpen(true)
                                                   }} size="m">Zamítnout cestovní náhrady
                                        </GovButton>

                                    </div>
                                }
                                {(roadCompensationReport) &&
                                    <div>
                                        <GovButton className="gov-flexbox--justify-center"
                                                   wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="solid"
                                                   onGov-click={() => {
                                                       setDocumentIsDownloading(true)
                                                       downloadTripsMonthReport()
                                                   }} size="m">Exportovat cestovní náhrady
                                        </GovButton>
                                    </div>
                                }
                                <GovPrompt open={rejectModalOpen} onGov-close={closeRejectModal}
                                           label="Zamítnout cestovní náhrady" role="dialog" label-tag="h5"
                                           wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                    chcete zamítnout tyto cestovní náhrady?
                                    <br/>

                                    <GovFormControl>
                                        <GovFormGroup>
                                            <GovFormInput size="m" variant="secondary" input-type="text"
                                                          multiline="true"
                                                          placeholder="Důvod zamítnutí"
                                                          rows="3"

                                                          value={note}
                                                          onGov-input={handleNoteChange}></GovFormInput>
                                        </GovFormGroup>
                                        <GovFormLabel slot="top" size="m">Pro zamítnutí je nutné vyplnit
                                            důvod</GovFormLabel>
                                    </GovFormControl>

                                    <GovButton onGov-click={closeRejectModal} variant="primary" size="m"
                                               type="base"
                                               slot="actions"
                                               wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                    </GovButton>
                                    <GovButton variant="error" size="m" type="solid" slot="actions"
                                               disabled={note === ""}
                                               wcag-label="Potvrdit smazání" onGov-click={declineRoadCompensations}>Zamítnout
                                    </GovButton>
                                </GovPrompt>

                            </div>
                            <div className="ml-30">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Celková doba na cestách:</th>
                                        <td>{calculateTotalWorkHours()} hodin</td>
                                    </tr>
                                    <tr>
                                        <th>Počet aktivit:</th>
                                        <td>{currentItems ? currentItems.length : 0}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="gov-flexbox gov-flexbox--justify-start">
                            <div>
                                {dateRange === labels.lastMonth &&
                                    <GovButton className="ml-5 gov-flexbox--justify-center"
                                               wcag-label="What exactly happens when you click?" variant="primary"
                                               type="solid"
                                               onGov-click={() => {
                                                   openModalTimesheet()
                                                   setCreateTimesheet(true)
                                               }} size="m">Uzavřít cestovní náhrady
                                    </GovButton>
                                }
                            </div>
                        </div>
                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ?
                            <table className="fixed" cellSpacing="0" cellPadding="0" width="100%">
                                <thead>
                                <tr>
                                    <th className="w-110px">
                                        Datum
                                        {sortDateOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortDateOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortDateOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }
                                    </th>
                                    <th className="w-110px">Začátek</th>
                                    <th className="w-110px">Konec</th>
                                    <th className="w-120px">Délka cesty</th>
                                    <th className="w-150px">
                                        <div className="gov-flexbox--justify-start">
                                            <GovDropdown position="left" className="min-height-400">
                                                <GovButton className="padding-0" variant="primary" size="xs" type="base"
                                                           wcag-label="What exactly happens when you click?"
                                                           right-icon="basic/chevron-down">Upřesňující typ
                                                    <GovIcon type="basic" name="chevron-down"
                                                             slot="right-icon"></GovIcon>
                                                </GovButton>
                                                <ul slot="list">
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="1"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["1"]}>
                                                                <GovFormLabel size="xs" slot="label">Administrativa
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="2"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["2"]}>
                                                                <GovFormLabel size="xs" slot="label">Obec
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="3"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["3"]}>
                                                                <GovFormLabel size="xs" slot="label">Projekt
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="7"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["7"]}>
                                                                <GovFormLabel size="xs" slot="label">Konzultace
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="4"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["4"]}>
                                                                <GovFormLabel size="xs" slot="label">Konzultace MMR
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="5"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["5"]}>
                                                                <GovFormLabel size="xs" slot="label">Školení
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                    <li>
                                                        <GovButton variant="secondary" size="xs" type="base"
                                                                   wcag-label="Home">
                                                            <GovFormCheckbox size="xs" value="6"
                                                                             onGov-change={handleCheckboxChange}
                                                                             checked={filtersByTimesheetType["6"]}>
                                                                <GovFormLabel size="xs" slot="label">Cesta
                                                                </GovFormLabel>
                                                            </GovFormCheckbox>
                                                        </GovButton>
                                                    </li>
                                                </ul>
                                            </GovDropdown>
                                        </div>
                                    </th>
                                    <th className="w-350px">
                                        Důvod/program cesty
                                    </th>
                                    <th>
                                        Akce
                                    </th>

                                </tr>
                                </thead>

                                <tbody>
                                {currentItems.map((row, index) => (
                                    <tr key={index}>
                                        <td>{convertIsoToDateFormat(row.workReport.start)}</td>
                                        <td>{convertIsoToTime(row.workReport.start)}</td>
                                        <td>{convertIsoToTime(row.workReport.end)}</td>
                                        <td>{(calculateHoursWorkedFromIso(row.workReport.start, row.workReport.end))}</td>
                                        <td>{(mapTimeSheetType(row.workReport.type))}</td>
                                        <td>{row.description}</td>
                                        <td>
                                        <GovButton variant="primary" type="link" size="s"
                                                   value={row.id}
                                                   onGov-click={openRoadCompensationViewModal}>
                                            <GovIcon type="basic" name="eye"
                                                     slot="left-icon"></GovIcon>
                                        </GovButton>
                                        {((authority === "RC_EMPLOYEE" && row.tripMonthReportState === "REJECTED_BY_SUPERVISOR")
                                                || (row.tripMonthReportState === null)
                                                || (authority === "RC_LEADER" && (row.tripMonthReportState === "CREATED" || row.tripMonthReportState === "REJECTED_BY_HQ"))
                                                || (row.tripMonthReportState !== "APPROVED_BY_HQ" && (authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT"))
                                                || ((authority === "HEADQUARTERS" || authority === "HQ_WITH_REPORT") && (currentDay <= 10 || currentDay >= 25))) &&

                                            <>
                                                <GovButton className="ml-5" variant="primary" type="link" size="s"
                                                           value={row.id}
                                                           onGov-click={openEditRoadCompensationModal}>
                                                    <GovIcon type="basic" name="pencil-square"
                                                             slot="left-icon"></GovIcon>
                                                </GovButton>

                                                <GovButton className="ml-5"
                                                           value={row.id}
                                                           wcag-label="What exactly happens when you click?"
                                                           variant="error" type="link" size="s"
                                                           onGov-click={openDeleteModal}>
                                                    <GovIcon type="basic" name="trash"
                                                             slot="left-icon"></GovIcon>
                                                </GovButton>
                                                <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                                                           label="Smazat cestovní náhradu" role="dialog" label-tag="h5"
                                                           wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                                    chcete smazat tuto cestovní náhradu?<br/>Tato operace je nevratná.
                                                    <GovButton onGov-click={closeDeleteModal} variant="primary" size="m"
                                                               type="base" slot="actions"
                                                               wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                                    </GovButton>
                                                    <GovButton variant="error" size="m" type="solid"
                                                               slot="actions"
                                                               wcag-label="Potvrdit smazání"
                                                               onGov-click={deleteRoadCompensation}>Smazat
                                                    </GovButton>
                                                </GovPrompt>
                                            </>
                                        }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                            )}
                    </div>
                </div>
            </div>
            {modalTimesheetOpen &&
                <RoadCompensationModal user={user} id={currentRoadCompensationId} roadCompensations={roadCompensations}
                                       modalOpen={modalTimesheetOpen}
                                       onGovModalClose={closeEditModal}></RoadCompensationModal>
            }
            {roadCompensationViewModalOpen &&
                <TripReportDetailModal roadTripId={currentRoadCompensationId}
                                       onGovModalClose={closeRoadCompensationViewModal}></TripReportDetailModal>
            }
            {documentIsLoading &&
                <div>
                    <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                </div>
            }
            {documentIsDownloading &&
                <div>
                    <GovLoading>Prosím vyčkejte soubor se generuje.</GovLoading>
                </div>
            }
        </div>
    )
}
