import {
    GovBreadcrumbs, GovButton,
    GovFormControl,
    GovFormInput,
    GovFormLabel,
    GovFormSelect, GovIcon, GovModal,
    GovSkeleton,
    GovSpacer, GovTile, GovTiles, GovToast
} from "@gov-design-system-ce/react";
import PieChartCustomPercent from "../dashboardComponents/PieChartCustomPercent";
import HorizontalBarChartCustomForPhases from "../dashboardComponents/HorizontalBarChartCustomForPhases";
import HorizontalBarChartCustomConsultations from "../dashboardComponents/HorizontalBarChartCustomConsultations";
import HorizontalBarChartCustomTowns from "../dashboardComponents/HorizontalBarChartCustomTowns";
import Navigation from "../Navigation";
import documentationDoc from '../../components/assets/documents/Poradensky_portal_SEA-prirucka.docx';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getIcon} from "../utils/utils";



export function HelpContent() {
    return (
        <div className="mb-200">
            <Navigation/>
            <div className="gov-container">
                <div className="gov-container__content">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Návody</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                </div>
                <div className="gov-container">
                    <h2>Soubory ke stažení</h2>
                    <FontAwesomeIcon icon={getIcon(documentationDoc)}/>
                    <span> </span>
                    <a href={documentationDoc} download="Poradensky_portal_SEA-prirucka.docx">
                        Uživatelská příručka
                    </a>
                </div>
            </div>
        </div>
    )
}
