import Navigation from "../Navigation";
import {
    GovBreadcrumbs, GovButton,
    GovFormControl,
    GovFormInput,
    GovFormSelect,
    GovIcon, GovPagination, GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import {convertIsoToCustomFormat, convertIsoToDateFormat, parseJwt} from "../utils/utils";
import {useEffect, useState} from "react";
import EmployeeCard from "../../pageElements/EmployeeCard";



export function UserCardsContent() {
    const [currentPage, setCurrentPage] = useState(1);
    const [letterChosen, setLetterChosen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [userCards, setUserCards] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [chosenRegionalCenter, setChosenRegionalCenter] = useState('0');

    const itemsPerPage = 20;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;

    const [sortTownsOrder, setSortTownsOrder] = useState('asc');

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };
    const jwt = localStorage.getItem("jwt")

    const fetchData = (currentRequest) => {
        console.log(currentRequest)
        if (currentRequest === "?rcId=0") {
            currentRequest = "";
        }
        fetch(process.env.REACT_APP_API_URL + `/users/bio${currentRequest ? currentRequest : "" }`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData);
                const filteredData = jsonData.filter(user => user.role !== 'MMR' && user.role !== 'NON_ACTIVE');
                setUserCards(filteredData);
                setFilteredItems(filteredData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSearchChange = (event) => {
        setCurrentPage(1)
        setSearchTerm(event.target.value);
        const filtered = userCards.filter(user =>
            user.lastname.toLowerCase().includes(event.target.value.toLowerCase()) ||
            (user.email && user.email.toLowerCase().includes(event.target.value.toString().toLowerCase()))
        );
        setFilteredItems(filtered);

    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChosenRegionalCenter(selectedValue);
        setSearchTerm('');

        fetchData(selectedValue ? `?rcId=${selectedValue}` : "");

    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const handleLetterClick = (letter) => {
        setCurrentPage(1)
        if(letter === ''){
            setLetterChosen(false)
        }
        else {
            setLetterChosen(true)
        }
        if(userCards.length > 0) {
            const filtered = userCards.filter(project =>
                project.lastname.toLowerCase().startsWith(letter.toLowerCase())
            );
            setFilteredItems(filtered);
        }

    };

    useEffect(() => {
        fetchData();
    }, []);




    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    return (
        <div>
            <Navigation active={"user-cards"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Medailonky</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-title--beta">Přehled medailonků: </div>

                        <div className="gov-flexbox search-row-width">
                            <GovFormInput onGov-input={handleSearchChange}
                                          value={searchTerm}
                                          placeholder="Zadejte email nebo příjmení zaměstnance" size="m"
                                          variant="secondary">
                                <GovIcon type="basic" name="search" slot="right-icon"></GovIcon>
                            </GovFormInput>

                        </div>
                        <GovSpacer size="l"></GovSpacer>
                            <div className="gov-flexbox search-row-width">
                                <GovFormControl>
                                    <GovFormSelect size="m" variant="secondary"
                                                   value={chosenRegionalCenter}
                                                   onGov-change={handleSelectChange}>
                                        <option disabled="disabled" value="">Regionální centrum</option>
                                        <option value="0">Všechna regionální centra</option>
                                        <option value="1">Moravskoslezský kraj</option>
                                        <option value="2">Středočeský kraj a Praha</option>
                                        <option value="3">Ústecký a Karlovarský kraj</option>
                                        <option value="4">Královehradecký a Liberecký kraj</option>
                                        <option value="5">Pardubický kraj a kraj Vysočina</option>
                                        <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                        <option value="7">Zlínský a Jihomoravský kraj</option>
                                        <option value="8">Olomoucký kraj</option>
                                    </GovFormSelect>
                                </GovFormControl>
                            </div>

                        {/*{projectsListIsOld && authority === "HEADQUARTERS" && request === "" &&*/}
                        {/*    <GovMessage variant="warning"><p>Aktuálně pracujete se seznamem projektů, který byl naposledy aktualizován před více než 1 hodinou. Pro načtení aktuálního seznamu projektů klikněte na tlačítko níže.</p>*/}
                        {/*        <GovIcon type="basic" name="info" slot="icon"></GovIcon>*/}
                        {/*    </GovMessage>*/}
                        {/*}*/}
                        {/*{authority === "HEADQUARTERS" && request === "" &&*/}
                        {/*<div>*/}
                        {/*    <GovButton class="mt-10" size="m" type="solid" wcag-label="Smazat" variant="primary"*/}
                        {/*               onGov-click={() => {refreshProjectsList()}}>Obnovit seznam projektů*/}
                        {/*    </GovButton>*/}

                        {/*    <p>Poslední aktualizace seznamu {authority === "HEADQUARTERS" ? "všech" : ""} projektů proběhla: {localStorage.getItem("projectsLastUpdate") && convertIsoToCustomFormat(localStorage.getItem("projectsLastUpdate"))}</p>*/}
                        {/*</div>*/}
                        {/*}*/}

                        <div className="gov-container gov-flexbox gov-alphabet">
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('A')}>A
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('B')}>B
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('C')}>C
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Č')}>Č
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('D')}>D
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('E')}>E
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('F')}>F
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('G')}>G
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('H')}>H
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('CH')}>CH
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('I')}>I
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('J')}>J
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('K')}>K
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('L')}>L
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('M')}>M
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('N')}>N
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('O')}>O
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('P')}>P
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Q')}>Q
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('R')}>R
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Ř')}>Ř
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('S')}>S
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Š')}>Š
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('T')}>T
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('U')}>U
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('V')}>V
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('W')}>W
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('X')}>X
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Y')}>Y
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Z')}>Z
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Ž')}>Ž
                            </GovButton>
                            {letterChosen && <GovButton onGov-click={() => {
                                handleLetterClick('')}} wcag-label="What exactly happens when you click?" variant="primary"
                                                        size="xs">Odstranit výběr
                            </GovButton>}
                        </div>
                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ? (
                            currentItems.map((row) => (
                                <EmployeeCard key={row.id} user={row} />
                            ))
                        ) : (
                            <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                         animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                        )}

                        <div className="pagination-container">
                            {userCards ? (
                                <GovPagination onGov-page={handlePageChange} variant="primary" size="s" type="button" current={currentPage} total={filteredItems.length} page-size={itemsPerPage}
                                               max-pages="4" wcag-label="Main paging" wcag-page-label="Page"></GovPagination>
                            ) : (
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="3" width="1000px" height="3000px"></GovSkeleton>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </div>
                )
}
