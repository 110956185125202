import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs, GovButton,
    GovFormControl, GovFormFile,
    GovFormInput,
    GovFormLabel, GovFormMessage, GovFormSelect,
    GovIcon, GovLoading, GovMessage,
    GovSkeleton, GovSpacer
} from "@gov-design-system-ce/react";
import React, {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertDateToDDMMYYYY, convertIsoToLocalDate, getIcon, mapWorkAgreementType} from "../utils/utils";
import EmployeeCard from "../../pageElements/EmployeeCard";
import RichTextEditor from "../../pageElements/RichTextEditor";
import DOMPurify from "dompurify";

export function ProfileEditContent({userId}) {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));


    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [employeeNumber, setEmployeeNumber] = useState(user.employeeId);
    const [employeeAddress, setEmployeeAddress] = useState(null);
    const [employmentType, setEmploymentType] = useState("");
    const [employmentRate, setEmploymentRate] = useState(null);
    const [workContractFromDate, setWorkContractFromDate] = useState('');
    const [workContractToDate, setWorkContractToDate] = useState('');

    const [profilePhotoChanged, setProfilePhotoChanged] = useState(false);

    const [titleBefore, setTitleBefore] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [titleAfter, setTitleAfter] = useState(null);
    const [phone, setPhone] = useState(null);
    const [linkedIn, setLinkedIn] = useState(null);
    const [careerDescription, setCareerDescription] = useState(null);

    const [editFields, setEditFields] = useState(false);




    const [employeeNumberValid, setEmployeeNumberValid] = useState(false);
    const [employeeAddressValid, setEmployeeAddressValid] = useState(false);
    const [employmentTypeValid, setEmploymentTypeValid] = useState(false);
    const [employmentRateValid, setEmploymentRateValid] = useState(false);
    const [workContractFromDateValid, setWorkContractFromDateValid] = useState(false);
    const [workContractToDateValid, setWorkContractToDateValid] = useState(false);

    const [titleBeforeValid, setTitleBeforeValid] = useState(false);
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [titleAfterValid, setTitleAfterValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [linkedInValid, setLinkedInValid] = useState(false);


    const [userValid, setUserValid] = useState(false);


    function fetchUser() {
        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            setUser(jsonData);
            setEmployeeNumber(jsonData.employeeId);
            setEmployeeAddress(jsonData.address);
            jsonData.workAgreementTypeId ? setEmploymentType(jsonData.workAgreementTypeId) : setEmploymentType("");
            setEmploymentRate(jsonData.workAllocation);
            jsonData.workFrom && setWorkContractFromDate(convertIsoToLocalDate(jsonData.workFrom));
            jsonData.workTo && setWorkContractToDate(convertIsoToLocalDate(jsonData.workTo));
            setTitleBefore(jsonData.titleBefore);
            setFirstName(jsonData.firstname);
            setLastName(jsonData.lastname);
            setTitleAfter(jsonData.titleAfter);
            setPhone(jsonData.phone);
            setLinkedIn(jsonData.linkedIn);
            setCareerDescription(jsonData.bioText);

            setFirstNameValid(!!jsonData.firstname);
            setLastNameValid(!!jsonData.lastname);

            setPhoneValid(!!jsonData.phone);
            setLinkedInValid(!!jsonData.linkedIn);

            setEmployeeNumberValid(!!jsonData.employeeId);
            setEmployeeAddressValid(!!jsonData.address);
            setEmploymentTypeValid(!!jsonData.workAgreementTypeId);
            setEmploymentRateValid(!!jsonData.workAllocation);
            setWorkContractFromDateValid(!!jsonData.workFrom);
            setWorkContractToDateValid(!!jsonData.workTo);
            setUserFetched(true);
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}/driversLicense/FRONT`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (response.status === 204) {
                    // Handle 204 No Content response
                    return null;
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setDrivingLicenceFront(jsonData);
                    setDrivingLicenseFrontFetched(true);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}/driversLicense/BACK`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (response.status === 204) {
                    // Handle 204 No Content response
                    return null;
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setDrivingLicenceBack(jsonData);
                    setDrivingLicenseBackFetched(true);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}/bio/profilePicture`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (response.status === 404) {
                    setProfilePhotoFetched(true);
                    return null;
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response
            })
            .then(response => response.blob())
            .then(blob => {
                setProfilePhoto(blob);
                setProfilePhotoChanged(prev => !prev);
                setProfilePhotoFetched(true);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function checkErrors() {
        let ready = employeeAddressValid && employeeNumberValid && employmentRateValid && employmentTypeValid &&
            workContractFromDateValid && workContractToDateValid && firstNameValid && lastNameValid &&
            phoneValid;
        setUserValid(ready)
    }

    useEffect(() => {
        checkErrors()
    }, [employeeAddressValid, employeeNumberValid, employmentRateValid, employmentTypeValid, workContractFromDateValid, workContractToDateValid
        , firstNameValid, lastNameValid, phoneValid]);

    const handleEmployeeNumberChange = (event) => {
        if (isNumeric(event.target.value)) {
            setEmployeeNumberValid(true)
        } else {
            setEmployeeNumberValid(false)
        }
        setEmployeeNumber(event.target.value);
    };
    const handleEmployeeAddressChange = (event) => {
        if (event.target.value !== "") {
            setEmployeeAddressValid(true)
        } else {
            setEmployeeAddressValid(false)
        }
        setEmployeeAddress(event.target.value);
    };

    const handleEmploymentTypeChange = (event) => {
        if (event.target.value !== "") {
            setEmploymentTypeValid(true)
        } else {
            setEmploymentTypeValid(false)
        }
        setEmploymentType(event.target.value);
    };

    const handleEmploymentRateChange = (event) => {
        if (isNumeric(
            event.target.value
        )) {
            setEmploymentRateValid(true)
        } else {
            setEmploymentRateValid(false)
        }
        setEmploymentRate(event.target.value);
    };


    const handleWorkContractFromDateChange = (event) => {
        if (event.target.value !== "") {
            setWorkContractFromDateValid(true)
        } else {
            setWorkContractFromDateValid(false)
        }
        setWorkContractFromDate(event.target.value);
    };


    const handleWorkContractToDateChange = (event) => {
        if (event.target.value !== "") {
            setWorkContractToDateValid(true)
        } else {
            setWorkContractToDateValid(false)
        }
        setWorkContractToDate(event.target.value);
    };

    const handleTitleBeforeChange = (event) => {
        setTitleBefore(event.target.value);
    }

    const handleFirstNameChange = (event) => {
        if (event.target.value !== "") {
            setFirstNameValid(true)
        } else {
            setFirstNameValid(false)
        }
        setFirstName(event.target.value);
    }

    const handleLastNameChange = (event) => {
        if (event.target.value !== "") {
            setLastNameValid(true)
        } else {
            setLastNameValid(false)
        }
        setLastName(event.target.value);
    }

    const handleTitleAfterChange = (event) => {
        setTitleAfter(event.target.value);
    }

    const handlePhoneChange = (event) => {
        if (isNumeric(event.target.value)) {
            setPhoneValid(true)
        } else {
            setPhoneValid(false)
        }
        setPhone(event.target.value);
    }

    const handleLinkedInChange = (event) => {
        setLinkedIn(event.target.value);
    }

    const handleCareerDescriptionChange = (event) => {
        setCareerDescription(event.target.value);
        console.log(careerDescription)
    }

    const jwt = localStorage.getItem("jwt")
    const [currentId, setCurrentId] = useState(localStorage.getItem("userId"));
    const [profilePhoto, setProfilePhoto] = useState(null);

    const [drivingLicenceFront, setDrivingLicenceFront] = useState(null);
    const [drivingLicenceBack, setDrivingLicenceBack] = useState(null);

    const [userFetched, setUserFetched] = useState(false);
    const [drivingLicenseFrontFetched, setDrivingLicenseFrontFetched] = useState(false);
    const [drivingLicenseBackFetched, setDrivingLicenseBackFetched] = useState(false);

    const [profilePhotoFetched, setProfilePhotoFetched] = useState(false);

    function patchEmployee() {
        fetch(process.env.REACT_APP_API_URL + `/users/${currentId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            body: JSON.stringify({
                "titleBefore": titleBefore,
                "titleAfter": titleAfter,
                "bioText": careerDescription,
                "linkedIn": linkedIn,
                "employeeId": employeeNumber,
                "workAllocation": employmentRate,
                "address": employeeAddress,
                "workAgreementTypeId": employmentType,
                "firstname": firstName,
                "lastname": lastName,
                "role": user.role,
                "phone": phone,
                "regionalCenterId": user.regionalCenter.id,
                "loginEmail": user.loginEmail,
                "workFrom": workContractFromDate + "T00:00:00",
                "workTo": workContractToDate + "T00:00:00"
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return response.json();
            })
            .then((response) => {
                setUser(response);
                console.log(response)
                setUser(response);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });


    }

    const [documentIsLoading, setDocumentIsLoading] = useState(false);

    function uploadProfilePhoto(file) {
        setDocumentIsLoading(true)
        console.log("loading")
        const formData = new FormData();
        formData.append('file', file);


        fetch(`${process.env.REACT_APP_API_URL}/users/${userId ? userId : user.id}/bio/profilePicture`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchUser();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function uploadDocument(file, side) {
        setDocumentIsLoading(true)
        console.log("loading")
        const formData = new FormData();
        formData.append('file', file);


        fetch(`${process.env.REACT_APP_API_URL}/users/${userId ? userId : user.id}/driversLicense/${side}/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchUser();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function fetchProfilePhoto() {
        fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}/bio/profilePicture`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);

                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;

                    a.download = "Profilová fotografie uživatele";


                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }

    function fetchDrivingLicenceDocument(side) {
        fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}/driversLicense/${side}/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    if (side === "FRONT") {
                        a.download = drivingLicenceFront.name;
                    } else {
                        a.download = drivingLicenceBack.name;
                    }

                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }

    const renderFieldInfo = (fieldName, fieldValue) => {
        return (

            <div>
                <h4>{fieldName}</h4>
                {userFetched ?
                    fieldName === "LinkedIn" ?
                        <a href={fieldValue} target="_blank" rel="noopener noreferrer">{fieldValue ? fieldValue : "Informace není uvedena"}</a>
                        :
                    <p>{fieldValue ? fieldValue : "Informace není uvedena"}</p>
                    :
                    <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                 shape="text"
                                 animation="progress" count="1" width="300px"
                                 height="30px"></GovSkeleton>
                }
            </div>
        );
    };


    useEffect(() => {
        fetchUser()
    }, [])

    let debounceTimeout;
    const debouncedUploadDocument = useCallback((file, type) => {
        clearTimeout(debounceTimeout);
        if (type === "PHOTO") {
            debounceTimeout = setTimeout(() => uploadProfilePhoto(file), 300);
        }
        else {
            debounceTimeout = setTimeout(() => uploadDocument(file, type), 300);
        }
    }, []);

    function exitEditMode() {
        setEditFields(false)
        setEmployeeNumber(user.employeeId);
        setEmployeeAddress(user.address);
        user.workAgreementTypeId ? setEmploymentType(user.workAgreementTypeId) : setEmploymentType("");
        setEmploymentRate(user.workAllocation);
        user.workFrom && setWorkContractFromDate(convertIsoToLocalDate(user.workFrom));
        user.workTo && setWorkContractToDate(convertIsoToLocalDate(user.workTo));
        setTitleBefore(user.titleBefore);
        setFirstName(user.firstname);
        setLastName(user.lastname);
        setTitleAfter(user.titleAfter);
        setPhone(user.phone);
        setLinkedIn(user.linkedIn);
        setCareerDescription(user.bioText);
    }

    return (
        <div>
            <Navigation/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/settings">Nastavení</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Změna osobních
                                údajů {userId && "(" + user.firstname + " " + user.lastname + ")"}</strong></li>
                    </ul>
                </GovBreadcrumbs>

                <div className="gov-container">

                    <div className="gov-container__content vh-80">


                        <div className="gov-title--beta flex-space-between">Změna osobních
                            údajů {userId && "(" + user.firstname + " " + user.lastname + ")"}
                            {editFields ?
                                <div>
                                    <GovButton className={"send-button"} onGov-click={() => {
                                        patchEmployee()
                                        setEditFields(false)
                                    }}
                                               wcag-label="What exactly happens when you click?"
                                               variant="primary" disabled={!userValid} type="solid" size="m">Uložit
                                    </GovButton>
                                    <GovButton className="ml-5" onGov-click={() => exitEditMode()}
                                               wcag-label="What exactly happens when you click?"
                                               variant="error" type="link" size="m">Zrušit
                                    </GovButton>
                                </div>
                                :
                                <GovButton onGov-click={() => setEditFields(true)}
                                           type="solid" name="pencil-square"
                                           iconLeft="basic/pencil-square"
                                           wcag-label="What exactly happens when you click?"
                                           variant="primary" size="m">
                                </GovButton>

                            }
                        </div>
                        {userId && userId !== localStorage.getItem("userId") &&
                            <GovMessage className="mb-20" slot="bottom" variant="error"><p>Upozornění: změna osobních
                                údajů jiného uživatele</p>
                                <GovIcon type="basic" name="exclamation-triangle-fill"
                                         slot="icon"></GovIcon>
                            </GovMessage>
                        }

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-grid--x3 gov-grid">
                                {editFields ?
                                    <form method="post" className="two-columns span-two">
                                        <div className="column">
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Titul před</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    placeholder="Titul před"
                                                    input-type="text"
                                                    value={titleBefore}
                                                    onGov-input={handleTitleBeforeChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Jméno</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    input-type="text"
                                                    placeholder="Jméno"
                                                    value={firstName}
                                                    onGov-input={handleFirstNameChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Příjmení</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    placeholder="Příjmení"
                                                    input-type="text"
                                                    value={lastName}
                                                    onGov-input={handleLastNameChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Titul za</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    placeholder="Titul za"
                                                    input-type="text"
                                                    value={titleAfter}
                                                    onGov-input={handleTitleAfterChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Telefon</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    input-type="text"
                                                    placeholder="776897453"
                                                    value={phone}
                                                    onGov-input={handlePhoneChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Odkaz LinkedIn</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    placeholder="https://www.linkedin.com/in/username"
                                                    input-type="text"
                                                    value={linkedIn}
                                                    onGov-input={handleLinkedInChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                        </div>
                                        <div className="column">
                                            <GovFormControl>
                                                <GovFormInput value={employeeNumber}
                                                              onGov-input={handleEmployeeNumberChange}
                                                              size="s" variant="secondary"
                                                              input-type="number"
                                                              placeholder="Číslo zaměstnance"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Číslo zaměstnance*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={employeeAddress}
                                                              onGov-input={handleEmployeeAddressChange}
                                                              size="s" variant="secondary"
                                                              input-type="text"
                                                              placeholder="Adresa trvalého bydliště"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Adresa trvalého
                                                    bydliště*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormSelect size="s" variant="secondary" value={employmentType}
                                                               onGov-change={handleEmploymentTypeChange}>
                                                    <option disabled="disabled" value="">Typ pracovního poměru</option>
                                                    <option value="1">HPP</option>
                                                    <option value="2">DPČ</option>
                                                </GovFormSelect>
                                                <GovFormLabel slot="top" size="s">Typ pracovního poměru*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={employmentRate}
                                                              onGov-input={handleEmploymentRateChange}
                                                              size="s" variant="secondary"
                                                              input-type="number"
                                                              placeholder="Výše úvazku"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Výše úvazku*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Smlouva na dobu od</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    input-type="date"
                                                    value={workContractFromDate}
                                                    onGov-input={handleWorkContractFromDateChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Smlouva na dobu do</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    input-type="date"
                                                    value={workContractToDate}
                                                    onGov-input={handleWorkContractToDateChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                        </div>
                                        <div className="full-width-column">

                                            <GovFormControl>
                                                <RichTextEditor value={careerDescription} setValue={setCareerDescription}/>
                                                {/*<GovFormInput size="s" variant="secondary"*/}
                                                {/*              input-type="text" multiline="true"*/}
                                                {/*              placeholder="Stručný kariérní popis"*/}
                                                {/*              value={careerDescription}*/}
                                                {/*              onGov-input={handleCareerDescriptionChange}>*/}
                                                {/*</GovFormInput>*/}
                                                <GovFormLabel slot="top" size="s">Stručný kariérní popis</GovFormLabel>
                                            </GovFormControl>
                                        </div>
                                        {!userValid &&
                                            <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné
                                                položky</p>
                                            </GovMessage>}
                                    </form>
                                    :
                                    <div className="two-columns span-two">
                                        <div className="column">
                                            {renderFieldInfo("Titul před", titleBefore)}
                                            {renderFieldInfo("Jméno", firstName)}
                                            {renderFieldInfo("Přijmení", lastName)}
                                            {renderFieldInfo("Titul za", titleAfter)}
                                            {renderFieldInfo("Telefon", phone)}
                                            {renderFieldInfo("LinkedIn", linkedIn)}
                                        </div>
                                        <div className="column">
                                            {renderFieldInfo("Číslo zaměstnance", employeeNumber)}
                                            {renderFieldInfo("Adresa trvalého bydliště", employeeAddress)}
                                            {renderFieldInfo("Typ pracovního poměru", mapWorkAgreementType(employmentType))}
                                            {renderFieldInfo("Výše úvazku", employmentRate)}
                                            {renderFieldInfo("Smlouva na dobu od", convertDateToDDMMYYYY(workContractFromDate))}
                                            {renderFieldInfo("Smlouva na dobu do", convertDateToDDMMYYYY(workContractToDate))}


                                        </div>
                                        <div className="full-width-column">
                                            <h4 className="mt-10">Stručný kariérní popis</h4>
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(user.bioText) }}></p>
                                        </div>
                                        <div className="full-width-column">
                                            <h3>Náhled medailonku</h3>
                                            {profilePhotoFetched ?
                                                <EmployeeCard user={{ ...user, profilePhotoChanged }} />                                            :
                                            <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                            shape="text"
                                                            animation="progress" count="1" width="300px"
                                                            height="30px"></GovSkeleton>
                                            }
                                        </div>
                                    </div>


                                }
                                <div className="ml-30">
                                    {drivingLicenseFrontFetched ?
                                        (!drivingLicenceFront ?
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Řidičský průkaz - přední
                                                        stránka</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "FRONT")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                                :
                                                <div>
                                                    <h3>Řidičský průkaz - přední strana</h3>
                                                    <FontAwesomeIcon icon={getIcon(drivingLicenceFront.name)}/>
                                                    <span> </span>
                                                    <a href={drivingLicenceFront.link} onClick={(event) => {
                                                        event.preventDefault();
                                                        fetchDrivingLicenceDocument("FRONT");
                                                    }}>
                                                        {drivingLicenceFront.name}
                                                    </a>
                                                    <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                        <GovFormControl>
                                                            <GovFormFile
                                                                onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "FRONT")}
                                                                max-file-size="2500"
                                                                accept=".pdf,.doc,.docx"
                                                                expanded="true"
                                                                displayAttachments="false">
                                                                <p>
                                                                    <GovButton variant="primary" size="m"
                                                                               type="outlined">Nahrát soubor ze
                                                                        zařízení
                                                                    </GovButton>
                                                                </p>
                                                                <GovSpacer size="m"></GovSpacer>
                                                                <p className="gov-text--s">Podporované formáty
                                                                    PDF,
                                                                    DOC</p></GovFormFile>
                                                        </GovFormControl>
                                                        <p slot="label">Nahrát jinou přední stranu</p>
                                                    </GovAccordionItem>
                                                </div>
                                        )
                                        :
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                     shape="text"
                                                     animation="progress" count="1" width="300px"
                                                     height="30px"></GovSkeleton>
                                    }
                                    {drivingLicenseBackFetched ?
                                        (!drivingLicenceBack ?
                                                <GovFormControl className="mt-10">
                                                    <GovFormLabel slot="top" size="s">Řidičský průkaz - zadní
                                                        strana</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "BACK")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                                :
                                                <div className="mt-10">
                                                    <h3>Řidičský průkaz - zadní strana</h3>
                                                    <FontAwesomeIcon icon={getIcon(drivingLicenceBack.name)}/>
                                                    <span> </span>
                                                    <a href={drivingLicenceBack.link} onClick={(event) => {
                                                        event.preventDefault();
                                                        fetchDrivingLicenceDocument("BACK");
                                                    }}>
                                                        {drivingLicenceBack.name}
                                                    </a>
                                                    <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                        <GovFormControl>
                                                            <GovFormFile
                                                                onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "BACK")}
                                                                max-file-size="616448"
                                                                accept=".pdf,.doc,.docx"
                                                                expanded="true"
                                                                displayAttachments="false">
                                                                <p>
                                                                    <GovButton variant="primary" size="m"
                                                                               type="outlined">Nahrát soubor ze
                                                                        zařízení
                                                                    </GovButton>
                                                                </p>
                                                                <GovSpacer size="m"></GovSpacer>
                                                                <p className="gov-text--s">Podporované formáty
                                                                    PDF,
                                                                    DOC</p></GovFormFile>
                                                        </GovFormControl>
                                                        <p slot="label">Nahrát jinou zadní stranu</p>
                                                    </GovAccordionItem>
                                                </div>
                                        )
                                        :
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                     shape="text"
                                                     animation="progress" count="1" width="300px"
                                                     height="30px"></GovSkeleton>

                                    }
                                    {profilePhotoFetched ?
                                        (!profilePhoto ?
                                                <GovFormControl className="mt-10">
                                                    <GovFormLabel slot="top" size="s">Profilová fotografie</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "PHOTO")}
                                                        max-file-size="616448"
                                                        accept=".jpeg,.jpg,.png"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            JPEG, JPG, PNG
                                                            </p></GovFormFile>
                                                </GovFormControl>
                                                :
                                                <div className="mt-10">
                                                    <h3>Profilová fotografie</h3>
                                                    <FontAwesomeIcon icon={getIcon("Profilová forografie")}/>
                                                    <span> </span>
                                                    <a href={profilePhoto.link} onClick={(event) => {
                                                        event.preventDefault();
                                                        fetchProfilePhoto();
                                                    }}>
                                                        Profilová fotografie
                                                    </a>
                                                    <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                        <GovFormControl>
                                                            <GovFormFile
                                                                onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "PHOTO")}
                                                                max-file-size="616448"
                                                                accept=".jpg,.jpeg,.png"
                                                                expanded="true"
                                                                displayAttachments="false">
                                                                <p>
                                                                    <GovButton variant="primary" size="m"
                                                                               type="outlined">Nahrát soubor ze
                                                                        zařízení
                                                                    </GovButton>
                                                                </p>
                                                                <GovSpacer size="m"></GovSpacer>
                                                                <p className="gov-text--s">Podporované formáty
                                                                    JPEG, JPG, PNG</p></GovFormFile>
                                                        </GovFormControl>
                                                        <p slot="label">Nahrát jinou profilovou fotografii</p>
                                                    </GovAccordionItem>
                                                </div>
                                        )
                                        :
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                     shape="text"
                                                     animation="progress" count="1" width="300px"
                                                     height="30px"></GovSkeleton>

                                    }
                                </div>

                            </div>

                        </div>
                        <div>
                            {documentIsLoading &&
                                <div>
                                    <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
