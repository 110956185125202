import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {LandDetailContent} from "../components/contentComponents/Land/LandDetailContent";

export function LandDetail() {
    return (
        <div>
            <Header></Header>
            <LandDetailContent></LandDetailContent>
            <Footer></Footer>
        </div>
    )
}
