import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {HelpContent} from "../components/contentComponents/HelpContent";

export function Help() {
    return (
        <div>
            <Header active={"help-active"}></Header>
            <HelpContent></HelpContent>
            <Footer></Footer>
        </div>
    )
}
