import {
    GovButton,
    GovFormCheckbox,
    GovFormControl, GovFormGroup,
    GovFormInput,
    GovFormLabel, GovFormRadio, GovFormSelect, GovIcon,
    GovMessage,
    GovModal, GovTabs, GovTabsItem
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {typeToText, userRoleToText} from "../utils/utils";

export function TownDetailModal(props) {
    const jwt = localStorage.getItem("jwt");

    const [councilHousingUnitsCount, setCouncilHousingUnitsCount] = useState();
    const [marketRent, setMarketRent] = useState();
    const [councilRent, setCouncilRent] = useState();

    const [councilHousingUnitsCountInvalid, setCouncilHousingUnitsCountInvalid] = useState(false);
    const [marketRentInvalid, setMarketRentInvalid] = useState(false);
    const [councilRentInvalid, setCouncilRentInvalid] = useState(false);
    const [aszCooperation, setAszCooperation] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const [dateOfContact, setDateOfContact] = useState();
    const [townContactEventMethodId, setTownContactEventMethodId] = useState('');
    const [contactedByUserId, setContactedByUserId] = useState('');
    const [townInterested, setTownInterested] = useState();
    const [housingFundRating, setHousingFundRating] = useState('');

    const [dateOfContactInvalid, setDateOfContactInvalid] = useState(true);
    const [townContactEventMethodIdInvalid, setTownContactEventMethodIdInvalid] = useState(true);
    const [contactedByUserIdInvalid, setContactedByUserIdInvalid] = useState(true);

    const [contactEventValid, setContactEventValid] = useState(false);

    function checkFormsError(){
        let ready = !dateOfContactInvalid && !townContactEventMethodIdInvalid && !contactedByUserIdInvalid;

        setContactEventValid(ready);
    }

    useEffect(() => {
        checkFormsError()
    }, [dateOfContact, townContactEventMethodId, contactedByUserId]);

    const [typeOfInputInformation, setTypeOfInputInformation] = useState(
        props.town.towns === undefined ? "Informace o obci" : "Kontakt obce");


    const [rcPeople, setRcPeople] = useState([]);
    const [modalOpen, setModalOpen] = useState(true);

    const fetchRcPeople = () => {
        fetch(process.env.REACT_APP_API_URL + "/rc/" + props.town.regionalCenter.id + "/employees", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setRcPeople(jsonData)
                console.log(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    console.log(props.town)

    function handleCouncilHousingUnitsCountChange(event) {
        if (event.target.value < 0) {
            setCouncilHousingUnitsCountInvalid(true);
        } else {
            setCouncilHousingUnitsCountInvalid(false);
        }
        setCouncilHousingUnitsCount(event.target.value);
    }

    function handleMarketRentChange(event) {
        if (event.target.value < 0) {
            setMarketRentInvalid(true);
        } else {
            setMarketRentInvalid(false);
        }
        setMarketRent(event.target.value);
    }

    function handleCouncilRentChange(event) {

        if (event.target.value < 0) {
            setCouncilRentInvalid(true);
        } else {
            setCouncilRentInvalid(false);
        }
        setCouncilRent(event.target.value);
    }

    const handleAszCooperationChange = (event) => {
        setAszCooperation(!aszCooperation);
    };

    const handleHousingFundRatingChange = (event) => {
        setHousingFundRating(event.target.value);
    };

    const handleDateOfContactChange = (event) => {
        if (event.target.value === "") {
            setDateOfContactInvalid(true);
        }
        else {
            setDateOfContactInvalid(false);
        }
        setDateOfContact(event.target.value);
    }

    const handleTownContactEventMethodIdChange = (event) => {
        if (event.target.value === "") {
            setTownContactEventMethodIdInvalid(true);
        }
        else {
            setTownContactEventMethodIdInvalid(false);
        }
        setTownContactEventMethodId(event.target.value);
    }


    const handleContactUserIdChange = (event) => {
        if (event.target.value === "") {
            setContactedByUserIdInvalid(true);
        }
        else {
            setContactedByUserIdInvalid(false);
        }
        setContactedByUserId(event.target.value);
    }

    const handleTownInterestedChange = (event) => {
        setTownInterested(!townInterested);
    }

    const handleTypeOfInputInformationChange = (event) => {
        setTypeOfInputInformation(event.detail.label)
    }

    function checkIfFormIsValid() {
        if (councilHousingUnitsCountInvalid || marketRentInvalid || councilRentInvalid) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }

    function updateTownDetail() {
        setModalOpen(false)
        fetch(process.env.REACT_APP_API_URL + "/towns/" + props.town.id + "/extended-info", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "marketRent": marketRent,
                "councilRent": councilRent,
                "councilHousingUnitsCount": councilHousingUnitsCount,
                "housingFundRating": housingFundRating === "" ? null : housingFundRating,
                "aszCooperation": aszCooperation
            })
        })
            .then(response => response.json())
            .then(data => {
                props.onGovModalClose();
            });
    }

    function updateTownContact() {
        setModalOpen(false)
        const collective = props.town.townCollectiveContactEvent !== undefined ? "collective/" : ""
        const url = process.env.REACT_APP_API_URL + "/towns/" + props.town.id + "/contact-event"
        const method = ((props.town.townContactEvent !== null && props.town.townContactEvent !== undefined) || (props.town.townCollectiveContactEvent !== null && props.town.townCollectiveContactEvent !== undefined) ) ? 'PUT' : 'POST'
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "employeeId": contactedByUserId,
                "dateOf": dateOfContact,
                "townContactEventMethodId": townContactEventMethodId,
                "interested": townInterested
            })
        })
            .then(response => response.json())
            .then(data => {
                props.onGovModalClose();
            });
    }

    useEffect(() => {
        checkIfFormIsValid()
    }, [councilHousingUnitsCountInvalid, marketRentInvalid, councilRentInvalid]);

    useEffect(() => {
        if (props.town) {
            if (props.town.towns === null) {
                setTypeOfInputInformation("Kontakt obce")
            }
            if (props.town.councilHousingUnitsCount !== null) {
                setCouncilHousingUnitsCount(props.town.councilHousingUnitsCount)
                setCouncilHousingUnitsCountInvalid(false)
            }
            if (props.town.marketRent !== null) {
                setMarketRent(props.town.marketRent)
                setMarketRentInvalid(false)
            }
            if (props.town.councilRent !== null) {
                setCouncilRent(props.town.councilRent)
                setCouncilRentInvalid(false)
            }
            if (props.town.housingFundRating !== null) {
                setHousingFundRating(props.town.housingFundRating)
            }
            if (props.town.aszCooperation !== null) {
                setAszCooperation(props.town.aszCooperation)
            }
            if(props.town.townContactEvent !== null && props.town.townContactEvent !== undefined) {
                if (props.town.townContactEvent.date !== null) {
                    setDateOfContact(props.town.townContactEvent.date)
                    setDateOfContactInvalid(false)
                }

                if (props.town.townContactEvent.employeeId !== null) {
                    setContactedByUserId(props.town.townContactEvent.employeeId)
                    setContactedByUserIdInvalid(false)
                }

                if (props.town.townContactEvent.townContactEventMethodId !== null) {
                    setTownContactEventMethodId(props.town.townContactEvent.townContactEventMethodId)
                    setTownContactEventMethodIdInvalid(false)
                }

                if (props.town.townContactEvent.interested !== null) {
                    setTownInterested(props.town.townContactEvent.interested)
                }
            }
            if(props.town.townCollectiveContactEvent !== null && props.town.townCollectiveContactEvent !== undefined) {
                if (props.town.townCollectiveContactEvent.date !== null) {
                    setDateOfContact(props.town.townCollectiveContactEvent.date)
                }

                if (props.town.townCollectiveContactEvent.employeeId !== null) {
                    setContactedByUserId(props.town.townCollectiveContactEvent.employeeId)
                }

                if (props.town.townCollectiveContactEvent.townContactEventMethodId !== null) {
                    setTownContactEventMethodId(props.town.townCollectiveContactEvent.townContactEventMethodId)
                }

                if (props.town.townCollectiveContactEvent.interested !== null) {
                    setTownInterested(props.town.townCollectiveContactEvent.interested)
                }
            }


        }
    }, [props.town])


    useEffect(() => {
        fetchRcPeople()
    }, []);



    return (
        <GovModal open={modalOpen} onGov-close={props.onGovModalClose}
                  label="Doplňující informace o obci"
                  wcag-close-label="Close dialog box with more information">

            <GovTabs onGov-change={handleTypeOfInputInformationChange} type="chip" variant="primary"
                     wcag-label="Basic information about the gov.cz design system">
                {props.town.towns === undefined ?
                <GovTabsItem label="Informace o obci"></GovTabsItem> : ""
                }
                <GovTabsItem label="Kontakt obce"/>
            </GovTabs>
            <div>
            {typeOfInputInformation === "Kontakt obce" ?
                <div>
                    <GovFormControl>
                        <GovFormCheckbox
                            size="m"
                            checked={!!props.town.townContactEvent}
                            disabled="true"
                        >
                            <GovFormLabel size="m"
                                          slot="label">Obec byla oslovena</GovFormLabel>
                        </GovFormCheckbox>
                    </GovFormControl>

                    <GovFormControl>
                        <GovFormLabel slot="top" size="s">Datum kdy byla obec oslovena*</GovFormLabel>
                        <GovFormInput
                            size="s" variant="secondary"
                            input-type="date"
                            value={dateOfContact}
                            onGov-input={handleDateOfContactChange}>
                        </GovFormInput>
                    </GovFormControl>

                    <GovFormControl>
                        <GovFormSelect value={townContactEventMethodId}
                                       onGov-change={handleTownContactEventMethodIdChange}
                                       size="s" variant="secondary">
                            <option disabled="disabled" value="">Jakým způsobem byla obec oslovena*</option>
                            <option value="1">Telefonicky</option>
                            <option value="2">Emailem</option>
                            <option value="3">Osobně</option>
                            <option value="4">V rámci absorpční kapacity</option>
                            <option disabled="disabled" value="5">V rámci vstupní schůzky</option>
                            {/*<option value="MIXED">Mix</option>*/}
                        </GovFormSelect>
                        <GovFormLabel slot="top" size="s">Jakým způsobem byla obec oslovena*</GovFormLabel>
                    </GovFormControl>

                    <GovFormControl>

                        <GovFormSelect size="s" placeholder="Kým byla obec oslovena"
                                       onGov-change={handleContactUserIdChange}
                                       value={contactedByUserId}>
                            <option disabled="disabled" value="">Kým byla obec oslovena*</option>
                            {rcPeople && rcPeople.length > 0 &&
                                rcPeople.map((person) => (
                                    <option key={person.id} value={person.id}>
                                        {person.firstname + " " + person.lastname + " - " + userRoleToText(person.role)}
                                    </option>
                                ))
                            }
                        </GovFormSelect>
                        <GovFormLabel slot="top" size="s">Kým byla obec oslovena</GovFormLabel>
                    </GovFormControl>

                    <GovFormControl>
                        <GovFormCheckbox
                            size="m"
                            onGov-change={handleTownInterestedChange}
                            checked={townInterested}
                        >
                            <GovFormLabel size="m"
                                          slot="label">Zájem o spolupráci</GovFormLabel>
                        </GovFormCheckbox>
                    </GovFormControl>
                    {!contactEventValid &&
                        <GovMessage variant="error">
                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                            <p>Nejsou vyplněny všechny povinné položky</p>
                        </GovMessage>
                    }

                    <GovButton variant="primary" size="m" type="solid"
                               onGov-click={updateTownContact}
                               disabled={!contactEventValid}
                               className="mt-10"
                               wcag-label="Open dialog box for more information"
                    >Kontaktovat
                    </GovButton>

                </div>
                :

                <div>
                    <div>
                        <GovFormControl>
                            <GovFormInput value={councilHousingUnitsCount}
                                          onGov-input={handleCouncilHousingUnitsCountChange}
                                          size="s" variant="secondary"
                                          input-type="number"
                                          placeholder="Počet obecních bytových jednotek"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Počet obecních bytových jednotek</GovFormLabel>
                        </GovFormControl>

                        <GovFormControl>
                            <GovFormInput value={marketRent} onGov-input={handleMarketRentChange}
                                          size="s" variant="secondary"
                                          input-type="number"
                                          placeholder="Tržní nájemné v obci (Kč/m2/měs)"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Tržní nájemné v obci
                                (Kč/m<sup>2</sup>/měs)</GovFormLabel>
                        </GovFormControl>


                        <GovFormControl>
                            <GovFormInput value={councilRent} onGov-input={handleCouncilRentChange}
                                          size="s" variant="secondary"
                                          input-type="number"
                                          placeholder="Obecní nájemné v obci (Kč/m2/měs)"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Obecní nájemné v obci
                                (Kč/m<sup>2</sup>/měs)</GovFormLabel>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormSelect size="s" variant="secondary"
                                           value={housingFundRating} onGov-change={handleHousingFundRatingChange}>
                                <option disabled="disabled" value="">Uveďte prosím hodnocení kvality obecního bytového fondu (jako ve škole)</option>
                                <option value="1">1 - vynikající</option>
                                <option value="2">2 - chvalitebná</option>
                                <option value="3">3 - dobrá</option>
                                <option value="4">4 - dostatečná</option>
                                <option value="5">5 - nedostatečná</option>
                            </GovFormSelect>
                            <GovFormLabel slot="top" size="m">Hodnocení kvality obecního bytového fondu</GovFormLabel>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormCheckbox
                                size="m"
                                onGov-change={handleAszCooperationChange}
                                checked={aszCooperation}
                            >
                                <GovFormLabel size="m"
                                              slot="label">Spolupráce s ASZ</GovFormLabel>
                            </GovFormCheckbox>
                        </GovFormControl>
                    <div>
                        {!formValid &&
                            <GovMessage variant="error"><p>Zadané údaje nejsou platné</p></GovMessage>}
                    </div>
                    <GovButton variant="primary" size="m" type="solid"
                               onGov-click={updateTownDetail}
                               className="mt-10"
                               disabled={!formValid}
                               wcag-label="Open dialog box for more information"
                    >Upravit
                    </GovButton>
                    </div>

                </div>
            }
            </div>
        </GovModal>
    )
}
