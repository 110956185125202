export function Footer() {
    function scrollToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
return(
    <footer className="u-bg-color--grey-dark gov-footer footer-padding">
        <div className="gov-container__content">
            <section className="gov-footer__upper">
                <div className="gov-footer__col">
                    <ul className="gov-list--plain">
                        <li>
                            <a href="https://sfpi.cz" target="_blank">
                                <svg width="176" height="50" viewBox="0 0 176 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_132_607)">
                                        <path d="M98.043 39.0478H73.3582V0.0168571H98.043V39.0478Z" fill="white"/>
                                        <path d="M0.847656 37.461L2.60149 32.7977C5.37551 34.5951 7.60906 35.492 9.30173 35.492C12.3728 35.492 13.9084 34.2139 13.9084 31.6581C13.9084 29.828 12.4252 28.2554 9.45885 26.9429C7.17248 25.9065 5.63297 25.1207 4.83901 24.5853C4.04461 24.0508 3.35584 23.4422 2.77138 22.7592C2.18647 22.077 1.75076 21.3512 1.46293 20.5828C1.17466 19.8152 1.03074 18.9897 1.03074 18.1089C1.03074 15.8288 1.86827 14.0511 3.54333 12.7721C5.21883 11.494 7.40793 10.8549 10.1133 10.8549C12.1545 10.8549 14.7283 11.494 17.8341 12.7721L16.4209 17.3317C14.4488 15.777 12.4688 14.9999 10.4795 14.9999C9.29249 14.9999 8.29388 15.2765 7.48275 15.8288C6.67119 16.3825 6.26541 17.0821 6.26541 17.9272C6.26541 19.7063 7.28646 21.0533 9.32769 21.969L12.8873 23.5751C15.0685 24.5596 16.6564 25.6831 17.6511 26.9429C18.6457 28.2036 19.143 29.784 19.143 31.6838C19.143 34.1708 18.2619 36.118 16.4993 37.5259C14.7366 38.9343 12.294 39.6374 9.17101 39.6374C6.22184 39.6374 3.44739 38.9116 0.847656 37.461Z" fill="white"/>
                                        <path d="M40.4254 4.40414C39.4132 4.0587 38.4714 3.88619 37.5986 3.88619C36.0807 3.88619 34.8281 4.45599 33.8427 5.59601C32.8569 6.7356 32.364 8.17795 32.364 9.92218C32.364 10.3887 32.4071 10.8723 32.4947 11.3732H38.2007V15.5182H32.4947V39.119H27.5219V15.5182H23.439V11.3732H27.5219C27.5219 7.83337 28.4025 5.05192 30.1656 3.03106C31.9278 1.0102 34.2573 -3.8147e-06 37.1537 -3.8147e-06C38.6021 -3.8147e-06 40.1543 0.25919 41.8127 0.777145L40.4254 4.40414Z" fill="white"/>
                                        <path d="M49.164 17.3574V33.523C49.4782 33.9895 50.141 34.4343 51.1533 34.8573C52.1646 35.2816 53.1509 35.492 54.1108 35.492C60.2877 35.492 63.376 32.0379 63.376 25.1294C63.376 21.6235 62.6432 19.0586 61.1776 17.435C59.7121 15.8127 57.365 14.9999 54.1368 14.9999C53.4392 14.9999 52.5836 15.2425 51.5718 15.7252C50.56 16.2087 49.7568 16.7528 49.164 17.3574ZM49.164 37.5904V50H44.1912V11.3729H49.164V13.6529C51.0485 11.7876 53.3256 10.8549 55.9953 10.8549C59.9735 10.8549 63.071 12.0821 65.2869 14.5337C67.5025 16.9863 68.6107 20.5793 68.6107 25.3106C68.6107 29.5248 67.4941 32.9663 65.2605 35.6345C63.0265 38.3031 59.7992 39.6374 55.5764 39.6374C54.3894 39.6374 53.1201 39.43 51.7681 39.0153C50.4161 38.601 49.5473 38.1258 49.164 37.5904Z" fill="white"/>
                                        <path d="M84.0483 3.91425C84.903 3.91425 85.6319 4.21222 86.2339 4.80816C86.836 5.40409 87.137 6.11633 87.137 6.94532C87.137 7.79174 86.836 8.51269 86.2339 9.10862C85.6319 9.70456 84.903 10.0025 84.0483 10.0025C83.2108 10.0025 82.4912 9.70456 81.8892 9.10862C81.2871 8.51269 80.9861 7.79174 80.9861 6.94532C80.9861 6.09978 81.2827 5.38231 81.876 4.79509C82.4688 4.20874 83.1932 3.91425 84.0483 3.91425ZM81.6132 39.0477V15.4469H73.3585V11.3016H86.586V39.0477H81.6132Z" fill="#2D2D2D"/>
                                        <path d="M102.698 28.3243L103.017 27.5297C103.179 27.6478 103.382 27.7467 103.625 27.8255C103.868 27.9048 104.085 27.9444 104.277 27.9444C104.617 27.9444 104.891 27.8525 105.097 27.6678C105.304 27.4835 105.407 27.2492 105.407 26.9643C105.407 26.7513 105.349 26.5531 105.235 26.3705C105.12 26.188 104.831 25.9885 104.369 25.7724L103.854 25.535C103.417 25.3333 103.113 25.0946 102.94 24.8184C102.767 24.5418 102.68 24.2094 102.68 23.8208C102.68 23.349 102.85 22.9574 103.186 22.6464C103.524 22.3354 103.958 22.1803 104.487 22.1803C105.193 22.1803 105.685 22.2935 105.961 22.5214L105.703 23.2724C105.588 23.1892 105.41 23.1086 105.171 23.0306C104.933 22.9531 104.712 22.9143 104.508 22.9143C104.211 22.9143 103.979 22.9975 103.809 23.1643C103.638 23.3316 103.553 23.5459 103.553 23.8078C103.553 23.9689 103.583 24.1157 103.645 24.2482C103.706 24.381 103.791 24.4913 103.9 24.581C104.009 24.6703 104.233 24.7953 104.569 24.9565L105.093 25.2026C105.529 25.4069 105.835 25.6509 106.011 25.9345C106.187 26.2181 106.275 26.5788 106.275 27.0161C106.275 27.491 106.082 27.8939 105.697 28.225C105.312 28.5561 104.796 28.7216 104.151 28.7216C103.583 28.7216 103.099 28.5892 102.698 28.3243Z" fill="white"/>
                                        <path d="M108.05 24.6369H107.51V23.9892H108.05V23.0221L108.879 22.7067V23.9892H110.162V24.6369H108.879V26.934C108.879 27.3225 108.946 27.5987 109.077 27.763C109.21 27.9272 109.423 28.0091 109.717 28.0091C109.929 28.0091 110.149 27.9559 110.376 27.8492L110.497 28.5706C110.154 28.6569 109.778 28.7 109.368 28.7C108.998 28.7 108.686 28.5641 108.432 28.2918C108.178 28.02 108.05 27.6767 108.05 27.262V24.6369Z" fill="white"/>
                                        <path d="M114.58 21.6274L113.594 23.0654H112.983L113.72 21.6274H114.58ZM114.392 26.3426C114.13 26.2851 113.947 26.2559 113.843 26.2559C113.424 26.2559 113.083 26.3626 112.818 26.5757C112.553 26.7887 112.421 27.0405 112.421 27.331C112.421 27.812 112.708 28.0524 113.28 28.0524C113.699 28.0524 114.069 27.8551 114.392 27.4609V26.3426ZM114.471 28.0825C114.139 28.4942 113.632 28.6998 112.948 28.6998C112.582 28.6998 112.264 28.5682 111.995 28.3047C111.726 28.0415 111.591 27.7144 111.591 27.3228C111.591 26.8532 111.799 26.4567 112.214 26.1331C112.628 25.8094 113.157 25.6474 113.799 25.6474C113.973 25.6474 114.171 25.6848 114.392 25.7597C114.392 25.0113 114.056 24.6367 113.38 24.6367C112.863 24.6367 112.464 24.7752 112.185 25.0514L111.836 24.3649C111.993 24.2386 112.21 24.1296 112.488 24.039C112.766 23.9484 113.031 23.9027 113.284 23.9027C113.962 23.9027 114.454 24.0552 114.761 24.3605C115.067 24.6659 115.221 25.1507 115.221 25.8155V27.4739C115.221 27.8795 115.343 28.1504 115.588 28.2855V28.6954C115.251 28.6954 114.998 28.6479 114.831 28.553C114.663 28.458 114.544 28.3016 114.471 28.0825Z" fill="white"/>
                                        <path d="M117.358 24.6369H116.817V23.9892H117.358V23.0221L118.186 22.7067V23.9892H119.469V24.6369H118.186V26.934C118.186 27.3225 118.253 27.5987 118.385 27.763C118.517 27.9272 118.731 28.0091 119.024 28.0091C119.237 28.0091 119.456 27.9559 119.683 27.8492L119.805 28.5706C119.462 28.6569 119.085 28.7 118.675 28.7C118.306 28.7 117.994 28.5641 117.74 28.2918C117.485 28.02 117.358 27.6767 117.358 27.262V24.6369Z" fill="white"/>
                                        <path d="M124.014 28.6136V25.9236C124.014 25.4313 123.94 25.0868 123.79 24.8894C123.64 24.6921 123.389 24.5936 123.037 24.5936C122.848 24.5936 122.65 24.6498 122.444 24.7622C122.237 24.8742 122.078 25.0127 121.968 25.1765V28.6136H121.14V23.9894H121.707L121.968 24.5849C122.242 24.1306 122.688 23.9027 123.308 23.9027C124.331 23.9027 124.843 24.5191 124.843 25.7511V28.6136H124.014Z" fill="white"/>
                                        <path d="M128.323 21.6274L127.338 23.0654H126.727L127.464 21.6274H128.323ZM126.958 28.6135V24.6803H126.317V23.9894H127.8V28.6135H126.958Z" fill="white"/>
                                        <path d="M135.394 22.8277C135.225 22.7702 135.069 22.7415 134.923 22.7415C134.67 22.7415 134.461 22.8364 134.297 23.0264C134.132 23.2163 134.05 23.4568 134.05 23.7473C134.05 23.8253 134.058 23.9059 134.072 23.9891H135.023V24.68H134.072V28.6137H133.243V24.68H132.563V23.9891H133.243C133.243 23.3988 133.391 22.9358 133.684 22.599C133.978 22.2623 134.366 22.0937 134.849 22.0937C135.09 22.0937 135.349 22.1368 135.625 22.2231L135.394 22.8277Z" fill="white"/>
                                        <path d="M137.273 26.2906C137.273 27.4507 137.688 28.0305 138.516 28.0305C138.904 28.0305 139.206 27.8754 139.426 27.5644C139.645 27.2534 139.755 26.8291 139.755 26.2906C139.755 25.145 139.342 24.5721 138.516 24.5721C138.138 24.5721 137.837 24.7246 137.611 25.0299C137.386 25.3353 137.273 25.7553 137.273 26.2906ZM136.401 26.2906C136.401 25.5771 136.595 25.0003 136.983 24.5612C137.372 24.1221 137.883 23.903 138.516 23.903C139.185 23.903 139.705 24.1138 140.073 24.5355C140.444 24.9572 140.628 25.5422 140.628 26.2906C140.628 27.0364 140.439 27.6241 140.061 28.0545C139.683 28.4849 139.168 28.7001 138.516 28.7001C137.85 28.7001 137.332 28.4827 136.959 28.0479C136.587 27.6132 136.401 27.0277 136.401 26.2906Z" fill="white"/>
                                        <path d="M144.911 28.6136V25.9236C144.911 25.4313 144.836 25.0868 144.687 24.8894C144.537 24.6921 144.286 24.5936 143.934 24.5936C143.745 24.5936 143.547 24.6498 143.341 24.7622C143.134 24.8742 142.975 25.0127 142.865 25.1765V28.6136H142.037V23.9894H142.604L142.865 24.5849C143.139 24.1306 143.585 23.9027 144.205 23.9027C145.228 23.9027 145.74 24.5191 145.74 25.7511V28.6136H144.911Z" fill="white"/>
                                        <path d="M150.416 25.0601C150.206 24.749 149.92 24.5939 149.556 24.5939C149.111 24.5939 148.752 24.7577 148.477 25.0858C148.202 25.4142 148.065 25.8315 148.065 26.3382C148.065 27.4521 148.576 28.0092 149.6 28.0092C149.731 28.0092 149.888 27.9683 150.071 27.8859C150.254 27.804 150.369 27.7169 150.416 27.625V25.0601ZM150.416 28.6091V28.268C150.128 28.5533 149.709 28.6958 149.159 28.6958C148.575 28.6958 148.101 28.4884 147.737 28.0737C147.375 27.6594 147.192 27.1066 147.192 26.4157C147.192 25.7218 147.401 25.1302 147.82 24.6392C148.239 24.1483 148.738 23.903 149.316 23.903C149.8 23.903 150.166 24.015 150.416 24.2398V22.0939H151.244V28.6091H150.416Z" fill="white"/>
                                        <path d="M103.749 35.598V38.2924C103.802 38.3699 103.913 38.4444 104.081 38.5145C104.25 38.5851 104.414 38.6204 104.574 38.6204C105.603 38.6204 106.118 38.0449 106.118 36.8936C106.118 36.309 105.996 35.8816 105.751 35.6111C105.507 35.3402 105.116 35.2051 104.578 35.2051C104.462 35.2051 104.32 35.2452 104.151 35.3262C103.982 35.4068 103.849 35.4974 103.749 35.598ZM103.749 38.9702V41.0385H102.921V34.6005H103.749V34.9808C104.063 34.6697 104.443 34.5142 104.888 34.5142C105.551 34.5142 106.067 34.7185 106.437 35.1276C106.806 35.5362 106.99 36.1347 106.99 36.9236C106.99 37.6263 106.805 38.1996 106.432 38.6444C106.06 39.0891 105.522 39.3113 104.818 39.3113C104.621 39.3113 104.409 39.2769 104.184 39.2076C103.958 39.1388 103.814 39.0595 103.749 38.9702Z" fill="white"/>
                                        <path d="M109.001 36.902C109.001 38.062 109.416 38.6418 110.245 38.6418C110.631 38.6418 110.935 38.4868 111.153 38.1757C111.374 37.8647 111.484 37.4404 111.484 36.902C111.484 35.7563 111.07 35.1834 110.245 35.1834C109.866 35.1834 109.565 35.3359 109.339 35.6413C109.114 35.9466 109.001 36.3666 109.001 36.902ZM108.129 36.902C108.129 36.1884 108.323 35.6117 108.711 35.1725C109.099 34.7334 109.61 34.5143 110.245 34.5143C110.913 34.5143 111.432 34.7252 111.802 35.1468C112.171 35.5685 112.356 36.1536 112.356 36.902C112.356 37.6478 112.167 38.2354 111.789 38.6658C111.41 39.0962 110.896 39.3114 110.245 39.3114C109.578 39.3114 109.059 39.094 108.687 38.6593C108.315 38.2245 108.129 37.639 108.129 36.902Z" fill="white"/>
                                        <path d="M116.726 35.6714C116.517 35.3604 116.23 35.2053 115.866 35.2053C115.422 35.2053 115.062 35.3691 114.787 35.6972C114.513 36.0256 114.375 36.4429 114.375 36.9496C114.375 38.0635 114.887 38.6206 115.91 38.6206C116.041 38.6206 116.198 38.5797 116.381 38.4973C116.564 38.4154 116.68 38.3283 116.726 38.2364V35.6714ZM116.726 39.2205V38.8794C116.438 39.1647 116.019 39.3072 115.469 39.3072C114.885 39.3072 114.411 39.0998 114.047 38.6851C113.685 38.2708 113.502 37.718 113.502 37.0271C113.502 36.3332 113.712 35.7416 114.131 35.2506C114.549 34.7597 115.048 34.5144 115.627 34.5144C116.11 34.5144 116.476 34.6264 116.726 34.8512V32.7053H117.555V39.2205H116.726Z" fill="white"/>
                                        <path d="M120.106 35.598V38.2924C120.158 38.3699 120.269 38.4444 120.437 38.5145C120.607 38.5851 120.771 38.6204 120.93 38.6204C121.96 38.6204 122.475 38.0449 122.475 36.8936C122.475 36.309 122.353 35.8816 122.108 35.6111C121.864 35.3402 121.473 35.2051 120.935 35.2051C120.819 35.2051 120.676 35.2452 120.507 35.3262C120.339 35.4068 120.205 35.4974 120.106 35.598ZM120.106 38.9702V41.0385H119.277V34.6005H120.106V34.9808C120.42 34.6697 120.8 34.5142 121.245 34.5142C121.908 34.5142 122.424 34.7185 122.793 35.1276C123.163 35.5362 123.347 36.1347 123.347 36.9236C123.347 37.6263 123.161 38.1996 122.789 38.6444C122.417 39.0891 121.879 39.3113 121.175 39.3113C120.977 39.3113 120.766 39.2769 120.54 39.2076C120.315 39.1388 120.171 39.0595 120.106 38.9702Z" fill="white"/>
                                        <path d="M125.358 36.902C125.358 38.062 125.773 38.6418 126.601 38.6418C126.988 38.6418 127.291 38.4868 127.51 38.1757C127.73 37.8647 127.84 37.4404 127.84 36.902C127.84 35.7563 127.427 35.1834 126.601 35.1834C126.223 35.1834 125.922 35.3359 125.696 35.6413C125.471 35.9466 125.358 36.3666 125.358 36.902ZM124.486 36.902C124.486 36.1884 124.679 35.6117 125.068 35.1725C125.456 34.7334 125.967 34.5143 126.601 34.5143C127.269 34.5143 127.789 34.7252 128.158 35.1468C128.528 35.5685 128.713 36.1536 128.713 36.902C128.713 37.6478 128.523 38.2354 128.146 38.6658C127.767 39.0962 127.252 39.3114 126.601 39.3114C125.935 39.3114 125.416 39.094 125.044 38.6593C124.671 38.2245 124.486 37.639 124.486 36.902Z" fill="white"/>
                                        <path d="M132.66 35.3908C132.48 35.2671 132.297 35.2052 132.114 35.2052C131.821 35.2052 131.564 35.3389 131.345 35.6068C131.125 35.8743 131.015 36.1971 131.015 36.5739V39.2251H130.186V34.6006H131.015V35.3389C131.318 34.7892 131.767 34.5143 132.363 34.5143C132.511 34.5143 132.725 34.54 133.004 34.5918L132.66 35.3908Z" fill="white"/>
                                        <path d="M135.914 40.0108C135.806 40.3044 135.58 40.5492 135.235 40.7448C134.891 40.9404 134.506 41.0384 134.082 41.0384V40.3044C134.427 40.3044 134.724 40.2246 134.972 40.0648C135.218 39.9049 135.343 39.7097 135.343 39.4797C135.343 39.2262 135.297 38.9766 135.207 38.7305C135.116 38.4843 135 38.1798 134.858 37.8174L133.593 34.6008H134.439L135.813 38.1759L137.048 34.6008H137.894L135.914 40.0108Z" fill="white"/>
                                        <path d="M143.153 32.8519C143.297 32.8519 143.418 32.9016 143.518 33.0009C143.619 33.1002 143.668 33.2187 143.668 33.3572C143.668 33.4984 143.619 33.6182 143.518 33.7175C143.418 33.8168 143.297 33.8665 143.153 33.8665C143.014 33.8665 142.894 33.8168 142.794 33.7175C142.694 33.6182 142.643 33.4984 142.643 33.3572C142.643 33.2161 142.693 33.0967 142.791 32.9987C142.891 32.9007 143.011 32.8519 143.153 32.8519ZM142.7 39.2251V35.2914H142.058V34.6005H143.528V39.2251H142.7Z" fill="white"/>
                                        <path d="M148.257 39.2249V36.535C148.257 36.0427 148.182 35.6981 148.032 35.5008C147.883 35.3035 147.632 35.205 147.28 35.205C147.091 35.205 146.893 35.2612 146.687 35.3736C146.48 35.4856 146.321 35.6241 146.211 35.7879V39.2249H145.382V34.6008H145.95L146.211 35.1963C146.484 34.7419 146.931 34.5141 147.55 34.5141C148.574 34.5141 149.086 35.1305 149.086 36.3625V39.2249H148.257Z" fill="white"/>
                                        <path d="M152.483 39.3113H152.265L150.263 34.5879H151.171L152.405 37.8259L153.666 34.5879H154.534L152.483 39.3113Z" fill="white"/>
                                        <path d="M157.635 35.2051C157.265 35.2051 156.956 35.3231 156.706 35.5593C156.467 35.7836 156.332 36.0628 156.3 36.397H158.887C158.887 36.0655 158.783 35.7893 158.573 35.568C158.343 35.3262 158.031 35.2051 157.635 35.2051ZM159.646 36.9925H156.278C156.278 37.5335 156.428 37.95 156.728 38.2405C156.992 38.4941 157.332 38.6204 157.749 38.6204C158.222 38.6204 158.618 38.4836 158.935 38.2105L159.284 38.8016C159.156 38.9284 158.96 39.0381 158.696 39.1301C158.364 39.2507 157.994 39.3113 157.587 39.3113C156.999 39.3113 156.501 39.1139 156.091 38.7197C155.634 38.285 155.406 37.7008 155.406 36.9668C155.406 36.204 155.64 35.5924 156.109 35.1319C156.527 34.7202 157.023 34.5142 157.596 34.5142C158.262 34.5142 158.784 34.6998 159.162 35.0714C159.529 35.4281 159.712 35.9017 159.712 36.4919C159.712 36.6731 159.69 36.8404 159.646 36.9925Z" fill="white"/>
                                        <path d="M160.814 38.9486L161.107 38.1715C161.569 38.4708 161.942 38.6206 162.223 38.6206C162.736 38.6206 162.991 38.4076 162.991 37.9816C162.991 37.6762 162.744 37.4144 162.25 37.1957C161.869 37.0228 161.612 36.8921 161.48 36.8028C161.347 36.7135 161.233 36.612 161.135 36.4983C161.038 36.3846 160.965 36.2639 160.917 36.1358C160.869 36.0073 160.845 35.8701 160.845 35.7233C160.845 35.3434 160.985 35.0468 161.264 34.8338C161.543 34.6207 161.909 34.5144 162.359 34.5144C162.699 34.5144 163.128 34.6207 163.646 34.8338L163.41 35.5939C163.082 35.3347 162.751 35.2053 162.42 35.2053C162.222 35.2053 162.056 35.2515 161.921 35.3434C161.785 35.4353 161.718 35.5517 161.718 35.6932C161.718 35.9895 161.887 36.2142 162.228 36.3667L162.821 36.6342C163.185 36.7984 163.449 36.9853 163.615 37.1957C163.781 37.4061 163.864 37.6692 163.864 37.9859C163.864 38.4002 163.717 38.7252 163.423 38.9595C163.13 39.1939 162.722 39.3115 162.202 39.3115C161.711 39.3115 161.248 39.1904 160.814 38.9486Z" fill="white"/>
                                        <path d="M165.568 35.2483H165.027V34.6005H165.568V33.6334L166.397 33.318V34.6005H167.68V35.2483H166.397V37.5453C166.397 37.9339 166.464 38.2101 166.596 38.3743C166.728 38.5385 166.942 38.6204 167.235 38.6204C167.448 38.6204 167.667 38.5673 167.893 38.4606L168.015 39.182C167.673 39.2682 167.296 39.3113 166.886 39.3113C166.517 39.3113 166.204 39.1754 165.95 38.9032C165.696 38.6313 165.568 38.2881 165.568 37.8733V35.2483Z" fill="white"/>
                                        <path d="M170.205 32.8519C170.348 32.8519 170.469 32.9016 170.569 33.0009C170.67 33.1002 170.72 33.2187 170.72 33.3572C170.72 33.4984 170.67 33.6182 170.569 33.7175C170.469 33.8168 170.348 33.8665 170.205 33.8665C170.066 33.8665 169.945 33.8168 169.845 33.7175C169.745 33.6182 169.695 33.4984 169.695 33.3572C169.695 33.2161 169.744 33.0967 169.843 32.9987C169.942 32.9007 170.062 32.8519 170.205 32.8519ZM169.752 39.2251V35.2914H169.11V34.6005H170.58V39.2251H169.752Z" fill="white"/>
                                        <path d="M175.958 34.9763L175.548 35.5548C175.465 35.4716 175.315 35.3919 175.102 35.3174C174.888 35.2429 174.678 35.205 174.471 35.205C174.021 35.205 173.663 35.3614 173.398 35.6737C173.133 35.9856 173.001 36.4143 173.001 36.9579C173.001 37.499 173.136 37.9115 173.407 38.1951C173.677 38.4787 174.052 38.6207 174.532 38.6207C174.904 38.6207 175.28 38.4778 175.657 38.1929L175.984 38.8838C175.539 39.1687 174.989 39.3112 174.331 39.3112C173.695 39.3112 173.168 39.0999 172.752 38.6765C172.337 38.2535 172.128 37.6806 172.128 36.9579C172.128 36.2213 172.345 35.6297 172.776 35.1836C173.209 34.7375 173.799 34.5141 174.549 34.5141C174.791 34.5141 175.053 34.5646 175.335 34.6652C175.617 34.7659 175.825 34.8695 175.958 34.9763Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_132_607">
                                            <rect width="175.137" height="50" fill="white" transform="translate(0.847656)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="gov-footer__col">
                    <h3 className="gov-footer__headline">Informace</h3>
                    <ul className="gov-list--plain">
                        <li>
                            <a href="https://sfpi.cz/pro-media/" className="gov-link  gov-link--standalone  gov-link--inversed">KONTAKTY PRO MÉDIA</a>
                        </li>
                        <li>
                            <a href="https://sfpi.cz/zpracovani-osobnich-udaju/" className="gov-link  gov-link--standalone  gov-link--inversed">ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</a>
                        </li>
                    </ul>
                </div>
                <div className="gov-footer__col">
                    <h3 className="gov-footer__headline">
                        Kontakty
                    </h3>

                    <ul className="gov-list--plain">
                        <li className="u-color--white">
                            <a href="mailto:komunikace@sfpi.cz" className="gov-link gov-link--standalone gov-link--inversed">
                                <span className="gov-icon gov-icon--mail"></span> komunikace@sfpi.cz</a></li>
                        {/*<li className="u-color--white">*/}
                        {/*    <a href="tel:+420605072069" className="gov-link gov-link--standalone gov-link--inversed">*/}
                        {/*        <span className="gov-icon gov-icon--phone"></span> +420 605 072 069</a>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                <div className="gov-footer__col">
                    <h3 className="gov-footer__headline">
                        Sledujte SFPI
                    </h3>

                    <ul className="gov-list--plain gov-list--inline">
                        <li>
                            <a href="https://www.facebook.com/sfpi.cz" className="gov-link--inversed">
                                <span className="gov-icon gov-icon--facebook"></span>
                            </a>
                        </li>
                        <li><a href="https://www.instagram.com/sfpi.cz/?hl=cs" className="gov-link--inversed">
                            <span className="sfpi-icon sfpi-icon--instagram sfpi-icon--no_margin"></span>
                        </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCUCpVo8pJ4soLcXCfmpPF0w" className="gov-link--inversed">
                                <span className="gov-icon gov-icon--youtube"></span>
                            </a>
                        </li>
                    </ul>
                </div>


                <button id="fo-scrollBtn" onClick={scrollToTop} className="gov-button gov-button--primary-outlined gov-button--inversed gov-footer__scroll-up gov-js--scroll-up" aria-labelledby="fo-scrollBtn">

            <span  className="u-sr-only gov-button__label">
                Zpět nahoru
            </span>
                </button>

            </section>
            <hr />
                <section className="gov-footer__lower">
                    <p className="gov-footnote">
                        2025 © Státní fond podpory investic
                    </p>

                </section>
        </div>
    </footer>)
}


